import React, { useMemo, useState, useEffect,useRef} from "react";
import "./CollectedBills.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Slider from "react-slick";
import { Button } from "../../../components/Button";
import { ReactComponent as ExcelIcon } from "../../../components/icons/excel.svg";

import { useTable } from "react-table";
import { CustomNextArrow, CustomPrevArrow } from "./CustomArrow";
import getCollectedBills from "../../../actions/creation/getCollectedBills";
import ImageViewer from "./imageCollectedBills";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked"; 
import DeleteIcon from "@material-ui/icons/Delete"; 
import getAssignSku from "../../../actions/creation/getAssignSku";
import CustomDropdown from "./CollectedBillsDropdown";
import Add from "@material-ui/icons/Add";
import CircularProgress from '@material-ui/core/CircularProgress';

import ImageIcon from "@material-ui/icons/Image";
import { DASHBOARDROUTES } from "../../../helpers/constants/routes";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField
} from "@material-ui/core"; 
import { triggerSimpleAjax } from "../../../helpers/httpHelper";
import { DELETE_COLLECTED_BILLS_SKU } from "../../../helpers/constants/apiUrl";
import createSkuCollectedBills from "../../../actions/creation/createSKUBills";
import getAddNewSkuData from "../../../actions/creation/getNewSkuData";
import createaddskuexcel from "../../../actions/creation/createAddNewSkuExcel";
import { black100, black200, black300, black500, blue300} from "../../../theme/colors";
import { AppConfig } from '../../../app-config';
import getGstAndPanDetails from "../../../actions/creation/getGstAndPanDetails";
import { bindActionCreators } from "redux";
import triggerNotification from "../../../actions/triggerNotification";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import updateInvoiceNumber from "../../../actions/creation/updateInvoiceNumber";

let getcategoryDetailsStaticQueryParams = "&no-pagination&exclude_retailers=true";

const CollectedBills = (props) => {
  const [selectedCard, setSelectedCard] = useState(0);
  const [isExpanded, setIsExpanded] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cardDataForSKus, setCardDataForSKus] = useState();
  const [cardTexts, setCardTexts] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [skuName, setSkuName] = useState([]);
  const [savedCard, setSavedCard] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [results, setResult] = useState([]);
  const [refreshData, setRefreshData] = useState(false); // State to trigger data refresh
  const [newAddedSKu, setNewAddedSKu] = useState([]); // State to trigger data refresh
  const [selectedSkuID, setSelectedSkuID] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [hasNewSku, setHasNewSku] = useState(false);
  const [editLoader,setEditLoader]=useState(false);
  const [localInvoiceHeaders, setLocalInvoiceHeaders] = useState([]);
  const [open, setOpen] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState(""); 
  const [invoiceDate, setInvoiceDate] = useState("");
  const [selectedSkuIds, setSelectedSkuIds] = useState([]);
  const [isEdited, setIsEdited] = useState(false);

  const enableEditAnswers = props?.enableEditAnswers;
  const cardContainerRef = useRef(null);
  const today = new Date().toISOString().split("T")[0];

  





useEffect(() => {
   
    fetchData();
  }, [refreshData]);

   async function fetchData() {
    
      try {
        const req = props.outletDetails.workflow_object;
        setLoading(true);
        const response = await getCollectedBills(req);        
        setTableData([])
        setCardDataForSKus(response);
        setCardTexts(
          response?.results.map(
            (card) => `${card.invoice_date}, ${card.invoice_no}`
          ) || []
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }


    const scroll = (scrollOffset) => {
      if (cardContainerRef.current) {
        cardContainerRef.current.scrollLeft += scrollOffset;
      }
    };



  //Required this for the New SKu Data

  useEffect(() => {
    if (selectedCard === "new-sku") {
      const req = props.outletDetails.workflow_object;
      async function fetchNewSKuData() {
        try {
          // const req = props.outletDetails.workflow_object;
          setLoading(true);
          const response = await getAddNewSkuData(req);
          setNewAddedSKu(response);
          setLoading(false);
        } catch (error) {
          setError(error.message || "Something went wrong");
          setLoading(false);
        }
      }
      fetchNewSKuData();
    }
  }, [selectedCard]);

  useEffect(() => {
    const subProjectSlugId = props.outletDetails.sub_project.slug;
    const req = `?sub_project__slug=${subProjectSlugId}${getcategoryDetailsStaticQueryParams}`;

    getAssignSku(req).then((response) => {
      const { results } = response;
      const skuNames = results.map((name) => name.sku_name);
      const skuData = results.map((item) => ({
        sku_name: item.sku_name,
        sku: item.sku,
      }));

      setResult(skuData);
      setSkuName(skuNames);
    });
  }, [props.outletDetails.sub_project.slug]);


  const handleEditClick = (index) => {
    setOpen(true);
  };


  const handleCardClick = (index) => {
    setSelectedCard(index);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async() => {
    const { notificationHandler } = props;
 
    const currentCardData= cardDataForSKus?.results[selectedCard];
    const payload = {
      invoice_no: invoiceNo,
      invoice_date: invoiceDate,
      sub_project:currentCardData.sub_project,
      workflow_object:currentCardData.workflow_object,
      simplifiedinvoiceheaderanswer:[],
      simplifiedinvoiceskudata:[],
      is_edit_invoicenumber:true,
      is_edit:false,
      is_edit_simplifiedinvoiceskudata:false,
      is_edit_simplifiedinvoiceheaderanswer:false,
      id:currentCardData.id,
    };
 
    try {
     const response= await updateInvoiceNumber(
        currentCardData.id, payload
      );
      notificationHandler({
        messageText: `Invoice number and date saved successfully.`,
        status: "SUCCESS",
      });
      setRefreshData((prev) => !prev);
    } catch (error) {
      setInvoiceNo(cardDataForSKus?.results[selectedCard]?.invoice_no);
 
      notificationHandler({
        messageText: error.message,
        status: "FAILED",
      });
    } finally {
      setEditLoader(false);
    }
 
    setOpen(false);
  };

  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };


  const handleOpenDialog = (skuID, rowIndex) => {
    setSelectedSkuID(skuID);
    setSelectedRowIndex(rowIndex);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const confirmDelete = () => {
    handleDeleteRow(); // Call your existing delete function
    setOpenDialog(false); // Close the dialog
  };


  const checkForNewSkus = () => {
    const newSkuExists = tableData.some(row => 
      (row.sku_name && skuName.length > 0 && !skuName.includes(row.sku_name)) || 
      (row.add_on_sku && skuName.length > 0 && !skuName.includes(row.add_on_sku)) // Check for both sku_name and add_on_sku, and ensure skuName is not empty
    );
  
    setHasNewSku(newSkuExists);
  };
  
  
  
  // Call this function whenever tableData changes
  useEffect(() => {
    checkForNewSkus();
  }, [tableData]);
  

const handleSaveNext = async () => {

  if (!cardDataForSKus || !cardDataForSKus.results || cardDataForSKus.results.length === 0) {
    console.warn("No card data available.");
    props.setIsSliderComplete(true);
    return;
  }

  if (selectedCard >= cardDataForSKus.results.length) {
    console.warn("Invalid selected card index.");
    props.setIsSliderComplete(true);
    return;
  }
  setSavedCard(selectedCard); // Save the currently selected card
  setEditLoader(true); // Show loader

  const currentCardData = cardDataForSKus?.results[selectedCard];


  // Map tableData by unique ID or sku_name to handle distinct rows
  const tableDataMap = tableData.reduce((map, row) => {
    map[row.id] = row; // Assuming `id` is unique, else use `sku_name`
    return map;
  }, {});



  let isOuterEdit = false;
  let isOuterSkuDataEdit = false;

  const getFieldName = (key) => {
    const fieldDetail = currentCardData.assigned_sku_template_field.find(
      (field) => field.id === parseInt(key)
    );
    return fieldDetail ? fieldDetail.name : key; // Fallback to key if name is not found
  };

  const getFieldType = (key) => {
    const fieldDetail = currentCardData.assigned_sku_template_field.find(
      (field) => field.id === parseInt(key)
    );
    return fieldDetail ? fieldDetail.type : key; // Fallback to key if type is not found
  };

  

  const updatedSkuData = currentCardData.simplifiedinvoiceskudata?.map(
    (skuItem) => {
      const matchingRow = tableDataMap[skuItem.id]; // Match by `id` instead of `sku_name`
  
  
      let isSkuDataEdited = false;
  
      const updatedSkuDetails = skuItem.simplifiedinvoiceskudetails.map(
        (skuDetail) => {
          const tableValue = matchingRow
            ? matchingRow[skuDetail.sku_template_field]
            : undefined;
  
  
          // Preserve the current single_input if tableValue is undefined
          if (tableValue === undefined) {
            return {
              ...skuDetail,
              is_edit: isSkuDataEdited,
              single_input: skuDetail.single_input,
            };
          }
  
          // If the tableValue is an empty string, preserve the existing single_input
          if (tableValue === "") {
            return {
              ...skuDetail,
              is_edit: true,
              single_input: "",
            };
          }
  
          // If the tableValue is different from the current single_input, update it
          if (tableValue !== skuDetail.single_input) {
            isSkuDataEdited = true;
            isOuterEdit = true;
            return {
              ...skuDetail,
              is_edit: true,
              single_input: tableValue,
            };
          }
  
          return skuDetail; // Return unchanged skuDetail if no updates are necessary
        }
      );
  
      // Handle additional fields from currentCardData.assigned_sku_template_field
      currentCardData.assigned_sku_template_field.forEach((field) => {
        const fieldExists = skuItem.simplifiedinvoiceskudetails.some(
          (detail) => detail.sku_template_field === field.id
        );
  
        if (!fieldExists) {
          const tableValue = matchingRow ? matchingRow[field.id] : "";
  
          updatedSkuDetails.push({
            id: null,
            sku_template_field: field.id,
            sku_template_field_type: field.type,
            sku_template_field_name: field.name,
            single_input: tableValue || "", // Use the found value or fallback to an empty string
            simplifiedinvoiceskuimageanswer: [],
            is_edit: true,
          });
  
          isSkuDataEdited = true;
          isOuterEdit = true;
        }
      });
  
      if (isSkuDataEdited) {
        isOuterSkuDataEdit = true;
      }
  
      const updatedSku = matchingRow && matchingRow.sku !== undefined 
        ? matchingRow.sku 
        : skuItem.sku; // Use the SKU from matchingRow or fallback
  
      const isSkuNameEdited =
        matchingRow && matchingRow.sku_name !== skuItem.sku_name;
  
      return {
        ...skuItem,
        simplifiedinvoiceskudetails: updatedSkuDetails, // Keep the updated details intact
        sku_name: matchingRow ? matchingRow.sku_name : skuItem.sku_name,
        sku: updatedSku, // Include `sku`
        is_edit: isSkuNameEdited || isSkuDataEdited,
        is_edit_simplifiedinvoiceskudetails: isSkuDataEdited,
      };
    }
  );
  
  // Check if updatedSkuData has changed from the original data
  const hasChanges = !currentCardData.simplifiedinvoiceskudata.every((originalSkuItem, index) => {
    const updatedSkuItem = updatedSkuData[index];
    return (
      originalSkuItem.sku === updatedSkuItem.sku &&
      originalSkuItem.sku_name === updatedSkuItem.sku_name &&
      JSON.stringify(originalSkuItem.simplifiedinvoiceskudetails) === JSON.stringify(updatedSkuItem.simplifiedinvoiceskudetails)
    );
  });

  
  // If changes are detected, set both edit flags to true
  if (hasChanges) {
    updatedSkuData.forEach((skuItem) => {
      skuItem.is_edit = true;
      skuItem.is_edit_simplifiedinvoiceskudetails = true;
    });
    isOuterSkuDataEdit = true; // Set global edit flag
  }
  
  // Now, updatedSkuData is ready to be used
  
  
    
    
    const newAddOnSkuRows = currentCardData.simplifiedinvoiceaddonsku?.map((addOnSkuItem) => {
      const matchingRow = tableDataMap[addOnSkuItem.id]; // Match by `id` instead of `sku_name`
    
          const updatedDetails = addOnSkuItem.simplifiedinvoiceskudetails.map((skuDetail) => {
        const tableValue = matchingRow
          ? matchingRow[skuDetail.sku_template_field]
          : undefined;
    
    
        // Preserve the current single_input if tableValue is undefined
        if (tableValue === undefined) {
          return {
            ...skuDetail,
            single_input: skuDetail.single_input,
          };
        }
    
        // If the tableValue is an empty string, preserve the existing single_input
        if (tableValue === "") {
          return {
            ...skuDetail,
            single_input: "", // Reset single_input if value is empty
          };
        }
    
        // If the tableValue is different from the current single_input, update it
        if (tableValue !== skuDetail.single_input) {
          return {
            ...skuDetail,
            single_input: tableValue, // Update with the new table value
          };
        }
    
        return skuDetail; // Return unchanged skuDetail if no updates are necessary
      });
    
      // Check assigned fields for the add-on SKU
      currentCardData.assigned_sku_template_field.forEach((field) => {
    
        const fieldExists = updatedDetails.some(
          (detail) => detail.sku_template_field === field.id
        );
    
    
        if (!fieldExists) {
          // Ensure matchingRow is defined and has a value for this field ID
          const tableValue = matchingRow && matchingRow[field.id] !== undefined
            ? matchingRow[field.id]
            : ""; // Use empty string as fallback if no value is found
    
    
          updatedDetails.push({
            sku_template_field: field.id,
            sku_template_field_type: field.type,
            sku_template_field_name: field.name,
            single_input: tableValue || "", // Fallback to empty string if value not found
            simplifiedinvoiceskuimageanswer: [],
          });
        }
      });
    
      // Return the updated add-on SKU row without is_edit
      return {
        id: addOnSkuItem.id,
        sku_name: addOnSkuItem.add_on_sku || "", // Use add_on_sku as sku_name
        sku: matchingRow ? matchingRow.sku : "", // Use SKU from matchingRow or fallback
        simplifiedinvoiceskudetails: updatedDetails, // Use updated details
      };
    });

    const newRows = tableData
    .filter((row) => row.isNew)
    .map((row) => {
      const matchedSku = results.find(
        (result) => result.sku_name === row.sku_name
      );
      return {
        sku_name: row.sku_name || "",
        sku: matchedSku ? matchedSku.sku : "",
        simplifiedinvoiceskudetails: Object.entries(row)
          .filter(([key]) => key !== "id" && key !== "isNew")
          .map(([key, value]) => ({
            sku_template_field: key,
            sku_template_field_name: getFieldName(key),
            sku_template_field_type: getFieldType(key),
            single_input: value,
            simplifiedinvoiceskuimageanswer: [],
          })),
      };
    });
    
    
    // Combine `newRows` with `newAddOnSkuRows` into `new_simplifiedinvoiceskudata`
    const payload = {
      ...currentCardData,
      simplifiedinvoiceskudata: updatedSkuData, // Update the SKU data
      new_simplifiedinvoiceskudata: [...newRows, ...newAddOnSkuRows], // Combine both new data sources
      is_edit: true,
      is_edit_simplifiedinvoiceskudata: true,
    };
    

  try {
    const response = await createSkuCollectedBills(currentCardData.id, payload);
    setRefreshData((prev) => !prev);
  } catch (error) {
    console.error("Error sending payload:", error);
  } finally {
    setEditLoader(false); // Hide loader
  }

  if (selectedCard < cardDataForSKus?.results.length - 1) {
    setSelectedCard(selectedCard + 1);
  } else {
    props.setIsSliderComplete(true);
  }
};


  const columns = useMemo(() => {
    const firstSkuData =
      cardDataForSKus?.results[selectedCard]?.assigned_sku_template_field || [];
      setInvoiceNo(cardDataForSKus?.results[selectedCard]?.invoice_no);
      setInvoiceDate(cardDataForSKus?.results[selectedCard]?.invoice_date) 
    const seenHeaders = new Set();
    return [
      {
        Header: "SKU Name", // Always have "SKU Name" as the first column
        accessor: "sku_name",
      },
      // Directly map over firstSkuData if it's an array
      ...(firstSkuData?.map((detail) => {
        if (!detail) return null; // Handle undefined detail
        // Using `name` (or other relevant field) as the unique header
        let uniqueHeader = detail.name || `Detail ${detail.id}`;
        let accessor = detail.id?.toString() || "undefined_id";

        // Handle duplicate accessor keys
        let index = 1;
        while (seenHeaders.has(accessor)) {
          accessor = `${uniqueHeader}_${index}`;
          index++;
        }
        seenHeaders.add(accessor);

        return {
          Header: uniqueHeader, // Display field name as header
          accessor: accessor, // Key for accessing this field's value
        };
      }) || []),
    ];
  }, [selectedCard, cardDataForSKus]);

 
  
 
  const data = useMemo(() => {
    const currentResults = cardDataForSKus?.results[selectedCard] || {};
  
    // Function to map data for both sku types, with conditional key for add_on_sku and including the id
    const mapSkuData = (skuDataArray, invoiceDate, isAddon = false) => (
      skuDataArray?.map((skuData) => ({
        id: skuData.id,  // Include the id field
        sku: skuData.sku,
        [isAddon ? 'add_on_sku' : 'sku_name']: isAddon ? skuData.add_on_sku : skuData.sku_name,
        invoice_date: invoiceDate,
        ...skuData.simplifiedinvoiceskudetails.reduce(
          (acc, detail) => ({
            ...acc,
            [detail.sku_template_field.toString()]: detail.single_input,
          }),
          {}
        ),
      })) || []
    );
  
    // Map both simplifiedinvoiceskudata and simplifiedinvoiceaddonsku
    const currentSkuData = mapSkuData(currentResults.simplifiedinvoiceskudata, currentResults.invoice_date);
    const currentAddonSkuData = mapSkuData(currentResults.simplifiedinvoiceaddonsku, currentResults.invoice_date, true);
  
    // Combine both data sets
    const combinedData = [...currentSkuData, ...currentAddonSkuData];
  
    const emptyRow = {};
    columns.forEach((column) => {
      emptyRow[column.accessor] = ""; // Set each key to an empty string
    });
  
    return combinedData;
  }, [selectedCard, columns, cardDataForSKus]);





  

  
  
  const addEmptyRow = () => {
    const emptyRow = {
      id: Date.now(), // Unique identifier using timestamp or you can use a UUID library
      isNew: true, // Flag to indicate it's a new row
    };
    columns.forEach((column) => {
      emptyRow[column.accessor] = ""; // Set each key to an empty string
    });
    setTableData((prevData) => [...prevData, emptyRow]);
  };

  useEffect(() => {
    // Initialize the tableData when data changes
    setTableData(data);
  }, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: tableData,
    });

 

  const handleInputChange = (event, rowIndex, columnId) => {
    const newValue = event.target.value;
    
    
    setTableData((prevData) => {
      const updatedData = [...prevData];
  
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        [columnId]: newValue,
      };
  
      
      return updatedData;
    });
  };



  const newAddedSKuTest = newAddedSKu?.results ?? [];

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  const getInvoiceHeaders = (data, index) => {
    const simplifiedInvoiceHeaderAnswer = data?.results?.[index]?.simplifiedinvoiceheaderanswer;
  
    if (!Array.isArray(simplifiedInvoiceHeaderAnswer)) {
      return [];
    }
  
    return simplifiedInvoiceHeaderAnswer.map((item) => ({
      header: item?.invoice_header_name ,
      singleInput: item?.single_input ,
      type: item?.invoice_header_type ,
      simplifiedinvoiceheaderanswerdetails: Array.isArray(item?.simplifiedinvoiceheaderanswerdetails)
        ? item.simplifiedinvoiceheaderanswerdetails.map((detail) => ({
            gst_legal_name: detail?.gst_legal_name ,
            gst_registration_status: detail?.gst_registration_status ,
            gst_trade_name: detail?.gst_trade_name ,
            pan_holder_name: detail?.pan_holder_name ,
          }))
        : [],
    }));
  };




 
  const handleActionButtonClick = (index) => {
    const { notificationHandler } = props;
    const updatedHeaders = [...localInvoiceHeaders];
  
    console.log("Selected index:", index);
    console.log("Before Update:", updatedHeaders);
  
    const req = `?input=${updatedHeaders[index].singleInput}&input_type=GST`;
  
    getGstAndPanDetails(req)
      .then((response) => {
        console.log("API Response:", response); // Debugging API response
  
        // Extract GST details from response
        const gstLegalName = response.lgnm || "";
        const gstTradeName = response.tradeNam || "";
        const gstRegistrationStatus = response.dty || "";
  
        // If simplifiedinvoiceheaderanswerdetails is empty, initialize it
        if (!updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.length) {
          updatedHeaders[index].simplifiedinvoiceheaderanswerdetails = [{
            gst_legal_name: gstLegalName,
            gst_trade_name: gstTradeName,
            gst_registration_status: gstRegistrationStatus,
          }];
        } else {
          updatedHeaders[index].simplifiedinvoiceheaderanswerdetails =
            updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.map((item) => ({
              ...item,
              gst_legal_name: gstLegalName || item.gst_legal_name,
              gst_trade_name: gstTradeName || item.gst_trade_name,
              gst_registration_status: gstRegistrationStatus || item.gst_registration_status,
            }));
        }
  
        // Mark as verified if all required fields are filled
        const isVerified = updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.every(
          (item) =>
            item.gst_legal_name &&
            item.gst_trade_name &&
            item.gst_registration_status
        );
        updatedHeaders[index].isVerified = isVerified;
  
        console.log("After Update:", updatedHeaders);
  
        setLocalInvoiceHeaders([...updatedHeaders]);
  
        notificationHandler({
          messageText: `GST number validated successfully.`,
          status: "SUCCESS",
        });
      })
      .catch((error) => {
        console.error("Error fetching GST details:", error);
        notificationHandler({
          messageText: `Invalid GST format. The given GST number is not in the proper format.`,
          status: "FAILED",
        });
      });
  };
  
  
  const handlePanButtonClick = (index) => {
    const { notificationHandler } = props;
    const updatedHeaders = [...localInvoiceHeaders];
  
    console.log("Selected index:", index);
    console.log("Before Update:", updatedHeaders);
  
    const req = `?input=${updatedHeaders[index].singleInput}&input_type=PAN`;
  
    getGstAndPanDetails(req)
      .then((response) => {
        console.log("API Response:", response); // Debug response
  
        // Extract PAN holder name from response
        const panHolderName = response.name || response.name_on_card || "";
  
        // If simplifiedinvoiceheaderanswerdetails is empty, initialize it
        if (!updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.length) {
          updatedHeaders[index].simplifiedinvoiceheaderanswerdetails = [{
            pan_holder_name: panHolderName,
          }];
        } else {
          updatedHeaders[index].simplifiedinvoiceheaderanswerdetails =
            updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.map((item) => ({
              ...item,
              pan_holder_name: panHolderName || item.pan_holder_name,
            }));
        }
  
        // Mark as verified if all entries have a pan_holder_name
        const isVerified = updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.every(
          (item) => item.pan_holder_name
        );
        updatedHeaders[index].isVerified = isVerified;
  
        console.log("AfterUpdate", updatedHeaders);
  
        setLocalInvoiceHeaders([...updatedHeaders]);
  
        notificationHandler({
          messageText: `PAN number validated successfully.`,
          status: "SUCCESS",
        });
      })
      .catch((error) => {
        console.error("Error fetching GST and PAN details", error);
        notificationHandler({
          messageText: `Invalid PAN number. The given PAN number is not in the proper format or invalid.`,
          status: "FAILED",
        });
      });
  };
  
  
  
  

  const handleSaveInvoiceHeader = async () => {
    const { notificationHandler } = props;
    const currentCardData = cardDataForSKus?.results[selectedCard];
    if (!currentCardData) return;

    const updatedSimplifiedInvoiceHeaders = [...(currentCardData.simplifiedinvoiceheaderanswer || [])];
    let hasChanges = false; // Track if any changes were made

    localInvoiceHeaders.forEach((localHeader) => {
        updatedSimplifiedInvoiceHeaders.forEach((header) => {
          console.log("header",header);
            if (header.invoice_header_type === "gst_number") {
                const gstHeader = localInvoiceHeaders.find((lh) => lh.type === "gst_number");
                const gstHeaderFromUpdateSimplifiedInvoice = updatedSimplifiedInvoiceHeaders.find(
                    (h) => h.invoice_header_type === "gst_number"
                );

                if (gstHeader && gstHeaderFromUpdateSimplifiedInvoice) {
                    const gstNumberDetails = gstHeader?.simplifiedinvoiceheaderanswerdetails?.[0];
                    const gstHeaderDetailsToUpdate = gstHeaderFromUpdateSimplifiedInvoice?.simplifiedinvoiceheaderanswerdetails;

                    if (gstNumberDetails && gstHeaderDetailsToUpdate) {
                        const { gst_legal_name, gst_registration_status, gst_trade_name } = gstNumberDetails;

                        if (gstHeaderDetailsToUpdate.length === 0) {
                            gstHeaderDetailsToUpdate.push({ gst_legal_name, gst_registration_status, gst_trade_name, is_edit: true });
                            hasChanges = true;
                            header.is_edit = true;
                        } else {
                            Object.assign(gstHeaderDetailsToUpdate[0], { gst_legal_name, gst_registration_status, gst_trade_name });
                            hasChanges = true;
                            header.is_edit = true;

                        }

                    }
                }
            }

            if (header.invoice_header_type === "pan_number") {
              const gstHeader = localInvoiceHeaders.find((lh) => lh.type === "pan_number");
              const gstHeaderFromUpdateSimplifiedInvoice = updatedSimplifiedInvoiceHeaders.find(
                  (h) => h.invoice_header_type === "pan_number"
              );

              if (gstHeader && gstHeaderFromUpdateSimplifiedInvoice) {
                  const gstNumberDetails = gstHeader?.simplifiedinvoiceheaderanswerdetails?.[0];
                  const gstHeaderDetailsToUpdate = gstHeaderFromUpdateSimplifiedInvoice?.simplifiedinvoiceheaderanswerdetails;

                  if (gstNumberDetails && gstHeaderDetailsToUpdate) {
                      const { pan_holder_name } = gstNumberDetails;

                      if (gstHeaderDetailsToUpdate.length === 0) {
                          gstHeaderDetailsToUpdate.push({ pan_holder_name, is_edit: true });
                          hasChanges = true;
                          header.is_edit = true;
                      } else {
                          Object.assign(gstHeaderDetailsToUpdate[0], { pan_holder_name });
                          hasChanges = true;
                          header.is_edit = true;

                      }

                  }
              }
          }

            // Check and update single_input value if needed
            if (header.invoice_header_name === localHeader.header) {
                if (header.single_input?.trim() !== localHeader.singleInput?.trim()) {
                    header.single_input = localHeader.singleInput;
                    header.is_edit = true; // Mark the header as edited
                    hasChanges = true; // Flag that there are changes
                }
            }
        });
    });

    console.log("updatedSimplifiedInvoiceHeaders", updatedSimplifiedInvoiceHeaders);

    // Prepare payload only if there are changes
    const payload = {
        ...currentCardData,
        is_edit: hasChanges,
        is_edit_simplifiedinvoiceheaderanswer: hasChanges,
        simplifiedinvoiceheaderanswer: updatedSimplifiedInvoiceHeaders,
    };

    try {
        await createSkuCollectedBills(currentCardData.id, payload);
        setRefreshData((prev) => !prev);
        notificationHandler({
            messageText: `Invoice Header saved Successfully.`,
            status: "SUCCESS",
        });
    } catch (error) {
        console.error("Error sending payload:", error);
    } finally {
        setEditLoader(false); // Hide loader
    }
};


  const handleDeleteRow = () => {
    triggerSimpleAjax(
      `${DELETE_COLLECTED_BILLS_SKU}${selectedSkuID}`, // API endpoint to delete the selected item
      "DELETE",
      {},
      () => {
        const updatedTableData = [...tableData]; // Create a copy of the table data
        updatedTableData.splice(selectedRowIndex, 1); // Remove the selected row from the copied data

        setTableData(updatedTableData); // Update the state with the new table data after deletion
        setRefreshData((prev) => !prev);
      },
      (error) => {
        console.log(error); // Handle any error if the API call fails
      }
    );
  };

  const simplifiedInvoiceSkuData =
    cardDataForSKus?.results[selectedCard]?.simplifiedinvoiceskudata || [];

  // const [filePath, setFilePath] = useState('');


  const handleCheckboxChange = (id, isChecked) => {
    setSelectedSkuIds(prevSelectedIds =>
      isChecked
        ? [...prevSelectedIds, id] // Add ID if checked
        : prevSelectedIds.filter(skuId => skuId !== id) // Remove ID if unchecked
    );
  };

  const handleChange = (e) => {
    setInvoiceNo(e.target.value);
    setError(e.target.value.trim() === ""); // Set error if the field is empty
  };


  const handleBlur = () => {
    setError(invoiceNo.trim() === ""); // Validate on blur
  };



  const handleExportToExcelButtonClick = (id) => {

    // Define the payload
    const payload = {
      "data_to_export": [
        {
          "id": selectedSkuIds // Add any specific IDs if needed
        }
      ],
      "workflow_object_id":props.outletDetails.workflow_object
      ,
      "add_on_sku": "Test"
    };

    // Call the API function
    createaddskuexcel(id, payload)
      .then((response) => {
        const filePath = response.file_path.replace(
          "/app/retailscan_backend/",
          `${AppConfig.api_url}`
        );
        // Save the file path to state
        // setFilePath(filePath);

        // Open the file path link in a new tab
        window.open(filePath, "_blank");
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  const invoiceHeaders = useMemo(
    () => getInvoiceHeaders(cardDataForSKus, selectedCard),
    [cardDataForSKus, selectedCard]
  );


  useEffect(() => {
    setLocalInvoiceHeaders([...invoiceHeaders]);
  }, [invoiceHeaders]);



  

  const handleSingleInputChange = (e, index) => {
    setIsEdited(true);
    const updatedHeaders = [...localInvoiceHeaders];
  
    updatedHeaders[index].singleInput = e.target.value;
  
    // Reset specific fields based on type
    if (updatedHeaders[index].type === "gst_number") {
      updatedHeaders[index].isVerified = false; // Set to "Validate"
      
      updatedHeaders[index].simplifiedinvoiceheaderanswerdetails = updatedHeaders[
        index
      ].simplifiedinvoiceheaderanswerdetails.map((item) => ({
        ...item,
        gst_legal_name: "",
        gst_trade_name: "",
        gst_registration_status: "",
      }));
    } else if (updatedHeaders[index].type === "pan_number") {
      updatedHeaders[index].isVerified = false; // Set to "Validate"
  
      updatedHeaders[index].simplifiedinvoiceheaderanswerdetails = updatedHeaders[
        index
      ].simplifiedinvoiceheaderanswerdetails.map((item) => ({
        ...item,
        pan_holder_name: "",
      
      }));
    }
  
    // Update the state
    setLocalInvoiceHeaders(updatedHeaders);
  };
  











  return (
    <React.Fragment>
      {loading ? (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <CircularProgress size={80} style={{ color: "#318CE7" }} />{" "}
        </div>
      ) : (
        <>
          
          <div
          style={{
            marginBottom: "10px",
            position: "sticky",
            padding: "10px 0",
            overflow: "hidden", // Prevents extra scrolling
            height: "auto", // Set a fixed height to avoid extra scrolling
          }}
        >
          <Box>
            <Box display="flex" alignItems="center" mb={2}>
              <IconButton
                onClick={() => scroll(-300)}
                style={{ backgroundColor: "#1976D2", color: "white" }}
              >
                <ArrowBack />
              </IconButton>
              <Box
                ref={cardContainerRef}
                style={{
                  display: "flex",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  scrollBehavior: "smooth",
                  width: "91%",
                  padding: "10px",
                  margin: "0 2px",
                  borderRadius: "8px",
                  backgroundColor: "#ffffff",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <>
                  {cardDataForSKus?.results.map((card, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        setSelectedCard(index);
                        // setErrorIndexes([]);
                        // setErrorMessages({});
                        setTimeout(() => {
                          fetchData();
                        }, 100);
                      }}
                      style={{
                        border:
                          savedCard !== null && index <= savedCard
                            ? "2px solid green"
                            : selectedCard === index
                            ? "2px solid #1976D2"
                            : `2px solid ${black200}`,
                        backgroundColor: "transparent",
                        margin: "4px 13px 0 0px",
                        height: "40px",
                        padding: "7px",
                        borderRadius: "5px",
                        display: "inline-block",
                        textAlign: "left",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="button-content"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {savedCard !== null && index <= savedCard ? (
                            <CheckCircleIcon
                              style={{ color: "green", marginRight: "8px" }}
                            />
                          ) : selectedCard === index ? (
                            <RadioButtonCheckedIcon
                              style={{ color: "#1976D2", marginRight: "8px" }}
                            />
                          ) : (
                            <RadioButtonUncheckedIcon
                              style={{ color: black200, marginRight: "8px" }}
                            />
                          )}

                          <span
                            style={{
                              color: "black",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              display: "inline-block",
                              maxWidth: "100%",
                            }}
                            title={cardTexts[index]}
                          >
                            {cardTexts[index]}
                          </span>

                          {enableEditAnswers && selectedCard === index && (
                            <span
                              style={{
                                margin: "0 8px",
                                height: "16px",
                                borderRight: "1px solid gray",
                              }}
                            />
                          )}
                        </div>

                        {enableEditAnswers && selectedCard === index && (
                          <EditIcon
                            style={{ color: "gray", cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent button click event
                              handleEditClick(index); // Open dialog when Edit icon is clicked
                            }}
                          />
                        )}
                      </div>
                    </button>
                  ))}

                  {/* Empty Popup Dialog */}
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Edit Invoice Header</DialogTitle>
                    <DialogContent>
                      <TextField
                        label="Invoice Number"
                        variant="outlined"
                        fullWidth
                        value={invoiceNo}
                        onChange={handleChange}
                        onBlur={handleBlur} // Validate when the user leaves the field
                        error={error} // MUI error state
                        helperText={error ? "Invoice Number is required" : ""} // Error message
                        style={{ marginBottom: "16px" }}
                      />
                      <TextField
                        label="Invoice Date"
                        variant="outlined"
                        fullWidth
                        value={invoiceDate}
                        onChange={(e) => setInvoiceDate(e.target.value)}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: today, // Restrict future dates
                        }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        color={black100}
                        bgColor={black500}
                      >
                        Close
                      </Button>
                      <Button
                        onClick={handleSave}
                        color="primary"
                        bgColor={blue300}
                        isDisabled={!invoiceNo}
                      >
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>

                <button
                  key="new-sku-data"
                  onClick={() => handleCardClick("new-sku")}
                  style={{
                    border: "1px solid #ccc",
                    margin: "4px 13px 0 0px", // Reduced margin
                    height: "40px",
                    borderRadius: "5px",
                  }}
                >
                  <div className="button-content">
                    <span className="button-text">New SKU Data</span>
                  </div>
                </button>
              </Box>

              <IconButton
                onClick={() => scroll(300)}
                style={{ backgroundColor: "#1976D2", color: "white" }}
              >
                <ArrowForward />
              </IconButton>
            </Box>
          </Box>

          </div>

          {selectedCard !== null && selectedCard !== "new-sku" && (
            <div className="table-container">
              <div
                className="empty-card"
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              >
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "barlow",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "20px",
                      textAlign: "left",
                      margin: 0, // Remove default margin for a cleaner look
                    }}
                  >
                    Invoice Details
                  </p>
                  {isExpanded ? (
                    <ExpandLessIcon
                      onClick={toggleExpandCollapse}
                      className="toggle-icon"
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      onClick={toggleExpandCollapse}
                      className="toggle-icon"
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>

                {isExpanded && (
                  <div
                    className="card-content"
                    style={{
                      padding: "10px 0",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    {localInvoiceHeaders && localInvoiceHeaders.length > 0 ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            rowGap: "8px",
                            columnGap: "8px",
                          }}
                        >
                          {localInvoiceHeaders.map((detail, index) => (
                            <div
                              key={index}
                              style={{
                                flex: "1 0 24%",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                padding: "5px 10px",
                                backgroundColor: "#f9f9f9",
                                borderRadius: "4px",
                                marginBottom: "10px",
                              }}
                            >
                              {console.log("detailsssss",detail)}
                              <span
                                style={{
                                  fontFamily: "barlow",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                  color: "#333",
                                  marginRight: "5px",
                                }}
                              >
                                {detail.header}:-
                              </span>
                              <input
                                type="text"
                                value={detail.singleInput}
                                onChange={(e) =>
                                  handleSingleInputChange(e, index)
                                }
                                style={{
                                  fontFamily: "barlow",
                                  fontSize: "13px",
                                  color: "#666",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                  padding: "5px",
                                  outline: "none",
                                  flex: 1,
                                  marginRight:
                                    detail.type === "gst_number" ? "5px" : "0",
                                }}
                              />
                              {/* Additional Fields */}
                              {detail.type === "gst_number" && (
                                <>
                                  <span
                                    style={{
                                      fontFamily: "barlow",
                                      fontSize: "13px",
                                      fontWeight: "600",
                                      color: "#333",
                                    }}
                                  >
                                    GST Legal Name:-
                                  </span>
                                  {console.log("detailName", detail)}
                                  <input
                                    type="text"
                                    value={
                                      detail
                                        ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                        ?.gst_legal_name || ""
                                    }
                                    style={{
                                      fontFamily: "barlow",
                                      fontSize: "13px",
                                      color: "#666",
                                      border: "1px solid #ccc",
                                      borderRadius: "4px",
                                      padding: "5px",
                                      outline: "none",
                                      flex: 1,
                                      marginRight: "15px",
                                    }}
                                  />
                                  <span
                                    style={{
                                      fontFamily: "barlow",
                                      fontSize: "13px",
                                      fontWeight: "600",
                                      color: "#333",
                                      marginRight: "5px",
                                    }}
                                  >
                                    GST Trade Name:-
                                  </span>
                                  <input
                                    type="text"
                                    value={
                                      detail
                                        ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                        ?.gst_trade_name || ""
                                    }
                                    style={{
                                      fontFamily: "barlow",
                                      fontSize: "13px",
                                      color: "#666",
                                      border: "1px solid #ccc",
                                      borderRadius: "4px",
                                      padding: "5px",
                                      outline: "none",
                                      flex: 1,
                                      marginRight: "5px",
                                    }}
                                  />
                                  <span
                                    style={{
                                      fontFamily: "barlow",
                                      fontSize: "13px",
                                      fontWeight: "600",
                                      color: "#333",
                                    }}
                                  >
                                    GST Registration Status:-
                                  </span>
                                  <input
                                    type="text"
                                    value={
                                      detail
                                        ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                        ?.gst_registration_status || ""
                                    }
                                    style={{
                                      fontFamily: "barlow",
                                      fontSize: "13px",
                                      color: "#666",
                                      border: "1px solid #ccc",
                                      borderRadius: "4px",
                                      padding: "5px",
                                      outline: "none",
                                      flex: 1,
                                      marginRight: "5px",
                                    }}
                                  />

                                  

                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      backgroundColor:
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_legal_name &&
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_registration_status &&
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_trade_name
                                          ? "rgb(76, 175, 80)"
                                          : "rgb(76, 175, 80)", // Can adjust the fallback color as needed
                                      color: "#fff",
                                      borderRadius: "2px",
                                      padding: "6px 12px",
                                      fontSize: "13px",
                                      cursor:
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_legal_name &&
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_registration_status &&
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_trade_name
                                          ? "not-allowed"
                                          : "pointer",
                                    }}
                                    onClick={() => {
                                      if (!detail?.isVerified) {
                                        handleActionButtonClick(index);
                                      }
                                    }}
                                  >
                                    {detail
                                      ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                      ?.gst_legal_name ||
                                    detail
                                      ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                      ?.gst_registration_status ||
                                    detail
                                      ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                      ?.gst_trade_name ? (
                                      <>
                                        <span style={{ marginRight: "8px" }}>
                                          Verified
                                        </span>
                                        <CheckCircleIcon
                                          style={{ fontSize: "16px" }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <span>Validate</span>
                                      </>
                                    )}
                                  </div>
                                </>
                              )}

                              {detail.type === "pan_number" && (
                                <>
                                  <span
                                    style={{
                                      fontFamily: "barlow",
                                      fontSize: "13px",
                                      fontWeight: "600",
                                      color: "#333",
                                    }}
                                  >
                                    PAN Holder Name:-
                                  </span>
                                  <input
                                    type="text"
                                    value={
                                      detail?.simplifiedinvoiceheaderanswerdetails?.[0]?.pan_holder_name || ""
                                    }
                                    
                                    style={{
                                      fontFamily: "barlow",
                                      fontSize: "13px",
                                      color: "#666",
                                      border: "1px solid #ccc",
                                      borderRadius: "4px",
                                      padding: "5px",
                                      outline: "none",
                                      flex: 1,
                                      marginRight: "15px",
                                    }}
                                  />

                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      backgroundColor: detail?.simplifiedinvoiceheaderanswerdetails?.[0]?.pan_holder_name

                                        ? "rgb(76, 175, 80)" // Green for "Verified"
                                        : "rgb(76, 175, 80)", // Gray for "Validate"
                                      color: "#fff",
                                      borderRadius: "2px",
                                      padding: "6px 12px",
                                      fontSize: "13px",
                                      cursor: detail?.simplifiedinvoiceheaderanswerdetails?.[0]?.pan_holder_name
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                    onClick={() => {
                                      if (!detail.isVerified) {
                                        handlePanButtonClick(index);
                                      }
                                    }}
                                  >
                                    { detail?.simplifiedinvoiceheaderanswerdetails?.[0]?.pan_holder_name ? (
                                      <>
                                        <span style={{ marginRight: "8px" }}>
                                          Verified
                                        </span>
                                        <CheckCircleIcon
                                          style={{ fontSize: "16px" }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <span>Validate</span>
                                      </>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                        </div>

                        <div
                          style={{
                            marginTop: "auto", // Push to the bottom
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {enableEditAnswers ? (
                            <button
                              style={{
                                backgroundColor: "#4CAF50",
                                color: "white",
                                padding: "10px 20px",
                                fontSize: "14px",
                                fontWeight: "600",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                transition: "background-color 0.3s",
                              }}
                              onClick={() => handleSaveInvoiceHeader()} // Replace with your save logic
                              onMouseOver={(e) =>
                                (e.target.style.backgroundColor = "#45a049")
                              } // Hover effect
                              onMouseOut={(e) =>
                                (e.target.style.backgroundColor = "#4CAF50")
                              } // Reset hover effect
                            >
                              Save
                            </button>
                          ) : null}
                        </div>
                      </>
                    ) : (
                      <p style={{ textAlign: "center", color: "#999" }}>
                        No invoice details found.
                      </p>
                    )}
                  </div>
                )}
              </div>

              <div>
                {tableData && tableData.length >= 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* New Button */}
                    {enableEditAnswers ? (
                      <button
                        className="add-empty-row-button"
                        onClick={addEmptyRow}
                      >
                        <Add style={{ color: "#ffffff", marginRight: "8px" }} />
                        Add New Sku
                      </button>
                    ) : null}
                    <button
                      className="total-image"
                      onClick={() => {
                        // Assuming `props.workflow_object_id` contains the value you want to pass
                        const url = `${DASHBOARDROUTES.DASHBOARD_IMAGE_VIEWER.replace(
                          ":workflow_object_id",
                          props.outletDetails.workflow_object
                        )}`;
                        window.open(url, "_blank");
                      }}
                    >
                      <ImageIcon
                        style={{ color: "#ffffff", marginRight: "8px" }}
                      />
                      Total Images
                    </button>
                  </div>
                ) : null}
                <div>
                  {tableData.length === 0 ? (
                    <div className="no-data-container">
                      <span>No sku entries received in bill</span>
                    </div>
                  ) : (
                    <div className="empty-card" style={{ maxWidth: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginBottom: "10px",
                          position: "sticky",
                          top: 0,
                          zIndex: 1000,
                          padding: "10px 0",
                        }}
                      ></div>
                      <div
                        style={{
                          overflowX: "auto",
                          maxHeight: "500px",
                          width: "100%",
                          marginTop: "-5%",
                        }}
                      >

                        <table
                          {...getTableProps()}
                          className="no-border"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            {headerGroups.map(
                              (headerGroup, headerGroupIndex) => (
                                <tr
                                  {...headerGroup.getHeaderGroupProps()}
                                  key={`headerGroup-${headerGroupIndex}`}
                                >
                                  {headerGroup.headers.map(
                                    (column, columnIndex) => (
                                      <th
                                        {...column.getHeaderProps()}
                                        key={`column-${columnIndex}`}
                                        style={{
                                          padding: "10px",
                                          wordWrap: "break-word",
                                          textAlign: "center", // Added this line to center the text
                                          alignItems: "center",
                                          position: "sticky",
                                          top: 0,
                                          zIndex: 10,
                                          backgroundColor: "#f5f5f5",
                                        }}

                                      >
                                        {column.render("Header")}
                                      </th>
                                    )
                                  )}
                                  <th>Action</th>{" "}
                                  {/* Add Action header for the delete icon */}
                                </tr>
                              )
                            )}
                          </thead>
                          <tbody
                            {...getTableBodyProps()}
                            style={{ fontSize: "12px" }}
                          >
                            {rows.map((row, rowIndex) => {
                              prepareRow(row);
                              return (
                                <tr
                                  {...row.getRowProps()}
                                  key={`row-${rowIndex}`}
                                  style={{ height: "30px" }}
                                >
                                  {/* SKU Name Column First */}
                                  {row.cells
                                    .filter(
                                      (cell) =>
                                        cell.column.id === "sku_name" ||
                                        cell.column.id === "add_on_sku"
                                    )
                                    .map((cell, cellIndex) => (
                                      <td
                                        {...cell.getCellProps()}
                                        key={`cell-${rowIndex}-${cellIndex}`}
                                        style={{
                                          padding: "4px 8px",
                                          verticalAlign: "middle",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "auto",
                                          }}
                                        >
                                          {cell.column.id === "sku_name" ? (
                                            <div>
                                              <CustomDropdown
                                                options={results.map(
                                                  (item) => item.sku_name
                                                )} // Only sku_name is shown in dropdown
                                                value={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ] || ""
                                                }
                                                onChange={(selectedValue) => {
                                                  // Find the corresponding sku for the selected sku_name
                                                  const selectedSku =
                                                    results.find(
                                                      (item) =>
                                                        item.sku_name ===
                                                        selectedValue
                                                    )?.sku;

                                                  const updatedTableData = [
                                                    ...tableData,
                                                  ];
                                                  // Update both sku_name and sku in the tableData
                                                  updatedTableData[rowIndex][
                                                    cell.column.id
                                                  ] = selectedValue;
                                                  updatedTableData[
                                                    rowIndex
                                                  ].sku = selectedSku || ""; // If no sku found, set empty

                                                  // Reset the add_on_sku when a new SKU is selected
                                                  updatedTableData[
                                                    rowIndex
                                                  ].add_on_sku = "";
                                                  setTableData(
                                                    updatedTableData
                                                  );
                                                }}
                                              />

                                              {/* Show 'New SKU' only if the selected value is not in the dropdown options */}
                                              {tableData[rowIndex]?.[
                                                cell.column.id
                                              ] &&
                                                !results.some(
                                                  (item) =>
                                                    item.sku_name ===
                                                    tableData[rowIndex][
                                                      cell.column.id
                                                    ]
                                                ) && (
                                                  <div
                                                    style={{
                                                      color: "#FFC72C",
                                                      marginTop: "3px",
                                                      fontSize: "11px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {
                                                      tableData[rowIndex][
                                                        cell.column.id
                                                      ]
                                                    }
                                                  </div>
                                                )}

                                              {/* Display the add_on_sku value below the dropdown if it exists */}
                                              {tableData[rowIndex]
                                                ?.add_on_sku && (
                                                <div
                                                  style={{
                                                    fontSize: "11px",
                                                    color: "red",
                                                    fontWeight: "bold",
                                                    marginTop: "5px", // Optional: Add some spacing between the dropdown and add_on_sku
                                                  }}
                                                >
                                                  Add-on SKU:{" "}
                                                  {
                                                    tableData[rowIndex]
                                                      .add_on_sku
                                                  }
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <div>
                                              {/* In case this cell is for add_on_sku, display it here */}
                                              <div
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {tableData[rowIndex]
                                                  ?.add_on_sku ||
                                                  "No Add-on SKU"}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    ))}

                                  {/* Other Columns */}
                                  {row.cells
                                    .filter(
                                      (cell) => cell.column.id !== "sku_name"
                                    )
                                    .map((cell, cellIndex) => (
                                      <td
                                        {...cell.getCellProps()}
                                        key={`cell-${rowIndex}-${cellIndex}`}
                                        style={{
                                          padding: "4px 8px",
                                          verticalAlign: "middle",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "auto",
                                          }}
                                        >
                                          <>
                                            <span
                                              style={{
                                                visibility: "hidden",
                                                position: "absolute",
                                                padding: "0",
                                                fontFamily: "inherit",
                                                fontSize: "inherit",
                                              }}
                                              id={`span-${rowIndex}-${cell.column.id}`}
                                            >
                                              {tableData[rowIndex]?.[
                                                cell.column.id
                                              ] || ""}
                                            </span>
                                            <input
                                              type="text"
                                              value={
                                                tableData[rowIndex]?.[
                                                  cell.column.id
                                                ] || ""
                                              }
                                              onChange={(event) => {
                                                handleInputChange(
                                                  event,
                                                  rowIndex,
                                                  cell.column.id
                                                );
                                                document.getElementById(
                                                  `span-${rowIndex}-${cell.column.id}`
                                                ).textContent =
                                                  event.target.value;
                                              }}
                                              style={{
                                                fontSize: "12px",
                                                padding: "2px 4px",
                                              }}
                                              className="line-input"
                                              placeholder={
                                                tableData[rowIndex]?.[
                                                  cell.column.id
                                                ]
                                                  ? ""
                                                  : "Enter Text"
                                              }
                                              title={
                                                tableData[rowIndex]?.[
                                                  cell.column.id
                                                ] || "Enter Text"
                                              }
                                            />
                                            <hr
                                              style={{
                                                margin: "0px 0",
                                                borderColor: "gray",
                                              }}
                                            />
                                          </>
                                        </div>
                                      </td>
                                    ))}

                                  <td style={{ textAlign: "center" }}>
                                    <DeleteIcon
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "20px",
                                        color: "grey",
                                      }}
                                      onClick={() =>
                                        handleOpenDialog(
                                          simplifiedInvoiceSkuData[rowIndex].id,
                                          rowIndex
                                        )
                                      } // Pass skuID and rowIndex here
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>

                      <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Confirm Deletion"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this row?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleCloseDialog}
                            color="primary"
                            bgColor="lightgrey"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={confirmDelete}
                            color="primary"
                            autoFocus
                          >
                            Delete
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  )}
                </div>
              </div>
              {enableEditAnswers ? (
                <button
                  className="save-next-button"
                  onClick={handleSaveNext}
                  disabled={hasNewSku || loading} // Disable button when loading
                >
                  {editLoader ? "Please Wait.." : "Save and Next"}
                </button>
              ) : null}
            </div>
          )}

          {selectedCard === "new-sku" && (
            <div>
              <p
                style={{
                  fontFamily: "barlow",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "20px",
                  textAlign: "left",
                }}
              >
                {/* Add any text here if needed */}
              </p>

              {/* Container to align the button to the right */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "20px",
                }}
              >
                <Button
                  size="medium"
                  bgColor={black300}
                  onClick={handleExportToExcelButtonClick}
                  style={{ marginRight: "10px" }}
                >
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "#3E96C8",
                    }}
                  >
                    <ExcelIcon />
                    Export to CSV
                  </span>
                </Button>
              </div>

              <table className="custom-table">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>New SKU's</th>
                    <th>Manufacturer</th>
                    <th>Brand</th>
                    <th>Variant</th>
                    <th>Sub Category</th>
                  </tr>
                </thead>
                <tbody>
                  {newAddedSKuTest.map((row, rowIndex) =>
                    row.simplifiedinvoiceaddonsku.map((sku, skuIndex) => (
                      <tr key={`${rowIndex}-${skuIndex}`}>
                        <td>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              handleCheckboxChange(sku.id, e.target.checked)
                            }
                          />
                        </td>
                        <td>{sku.add_on_sku || "N/A"}</td>
                        <td>{sku.manufacturer || "N/A"}</td>
                        <td>{sku.brand || "N/A"}</td>
                        <td>{sku.variant || "N/A"}</td>
                        <td>{sku.sub_category || "N/A"}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    notificationHandler: bindActionCreators(triggerNotification, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(CollectedBills);
