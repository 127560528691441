import React, {Fragment, useEffect, useState} from "react";
import styled, {css} from "styled-components";
import moment from "moment";
import {
  black600,
  black700,
  blue300,
  cinnabar300,
  curiousBlue300,
  green300,
  royalBlue300,
  white
} from "../../../theme/colors";
import {baseCaption} from "../../../theme/typography";
import {bold} from "../../../theme/typeface";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import {Button} from "../../../components/Button";
import {StatusTagStyle} from "../../../components/StatusTagStyle";
import getOutletActions from "../../../actions/outlet/getOutletActions";
import {getActionList} from "./helper/getActionList";
import {UpdateStatusModal} from "./modals/UpdateStatusModal";
import {WORKFLOW_KEYS} from "../../../helpers/constants/workflowKeys";
import ReactTooltip from "react-tooltip";
import {ToolTip} from "../../../components/ToolTip";
import {ACCEPT_OR_REJECT_FIELD_APP_CHANGES, GET_OUTLET_GROUP_SAVE,GET_SIMILAR_OUTLETS_ACTIONS} from "../../../helpers/constants/apiUrl";
import {triggerSimpleAjax} from "../../../helpers/httpHelper";
import ImageGallery from "../../../components/image_gallery/ImageGallery";
import capitializeFirstChar from "../../../helpers/utils/capitializeFirstChar";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from "@material-ui/icons/Close";
import themeSizes from "../../../theme/themeSizes";
import { SimilarOutletModal } from "./modals/SimilarOutletModal";

import { getCheckPendingRequestRetailerEdit } from "./getCheckPendingRequestRetailerEdit"
import {Error} from "@material-ui/icons";

const OutletMetaWrapper = styled.section`
  max-width: 100%;
  background: ${white};
  box-shadow: 0px 10px 24px rgba(91, 127, 220, 0.16);
  min-height: 160px;
  padding: 24px 35px;
  max-height: ${props => props.expand ? "100%" : "100px"};
  overflow: hidden;
  transition: max-height 0.7s ease-in-out;
`;

const RowWrapper = styled.div`
  display: flex;
  > div:first-child {
    width: 80%;
  }
`
const RowBlock = styled.div`
  display: flex;
  width: 100%;
  color: ${black600};

  @media ${themeSizes.mobile} {
    flex-direction:column;
  }

  & > div {
    width: 21%;
    border-right: 1px solid #dedede;

    :nth-child(1),
    :nth-child(4) {
      width: 30%;
    }

    :last-child {
      border-right: 0;
    }
    @media ${themeSizes.mobile} {
      width:100% !important;
      border-right: none
    }
  }
`;

const Title = styled.p`
  ${baseCaption}
  font-weight: ${bold};
  color: ${royalBlue300};
  text-transform: uppercase;
  margin: 8px 0 8px 0;
`;

const TitleZeroMargin = styled.p`
  ${baseCaption}
  font-weight: ${bold};
  color: ${royalBlue300};
  text-transform: uppercase;
  margin: 0;
`;

const Block = styled.div`
  ${baseCaption}
  padding: 0 14px;

  p:first-child {
    word-break: ${({wordBreak}) => wordBreak || "break-all"};
  }

  ${(props) =>
          props.blockWidth &&
          css`
            width: ${props.blockWidth};

            p {
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
            }
          `}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Location = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${black700};

  p {
    ${baseCaption}
    color: ${black600};
    display: flex;
  }
`;

const RowText = styled.p`
  display: flex;
  margin: ${(props) => props.margin || "0 0 8px"};

  > span:last-child {
    width: 55%;
    word-break: break-all;
  }

  justify-content: space-between;
`;

const ImageBlock = styled.div`
  width: 44px;
  height: 44px;
  background-image: url(${({image}) => image});
  background-repeat: no-repeat;
  background-size: contain;
`;

const LinkText = styled.span`
  ${baseCaption};
  color: ${curiousBlue300};
  cursor: pointer;
`;

const EditRowWrapper = styled.div`
  display: flex;

  p, h3 {
    flex: 33%;
  }
`

export const OutletMeta = ({
  id,
     outletName,
     address,
     region,
     city,
     state,
     zip_code,
     created,
     workflow_permission,
     subProjectType,
     workflowOutletId,
     status,
     outlet_id,
     rsid,
     inbound_source,
     saveClickedData,
     setSaveClickedData,
     selectedOutletDetails = [],
     question_groups,
     getOutletDetails,
     history,
     related_workflow_object = {},
     setWorkflowObjectActionConfig = () => {
     },
     selectedCallingTrackerReasonDetails = {},
     callingTrackerList = [],
     resetCallingtrackerResonDetails = () => {
     },
     triggerNotification,
     updateActionListAvailable,
     banner_image,
     setWorkflowStateChanged = () => {
     },
     workflowStateChanged,
     field_survey_start_datetime = new Date(),
     field_survey_end_datetime,
     field_survey_start_latitude,
     field_survey_end_latitude,
     field_survey_start_longitude,
     field_survey_end_longitude,
     notes_by_field_auditor,
     field_survey_start_location_accuracy,
     previous_address,
     distributor_gst_number,
     phone_number1,
     phone_number2,
     previous_phone_number1,
     previous_phone_number2,
     slug,
     qc_display_notes,
     calling_display_notes,
     client_remarks,
     calling_remarks,
     name,
     survey_not_done_notes_by_field_auditor,
     bulk_created_by,
     bulk_approved_by

   }) => {
  const [openUpdateModal, setUpdateModalOpen] = useState(false);
  const [actionList, setActionList] = useState([]);
  const [galleryImage, setGalleryImage] = useState([]);
  const [expand, setExpand] = useState(false)
  const [openSimilarOutletModal, setSimilarOutletModal] = useState(false);
  const [similarOutletData, setSimilarOutletData] = useState([]);
  const [isRetailerRequestPending, setIsRetailerRequestPending] = useState(false);
  const [countRetailerRequestPending, setCountRetailerRequestPending] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkPendingRequest = async () => {
    try {
      // Assuming 'workflowOutletId' is defined somewhere in the component
      setLoading(true);
      const workflow_object_id = workflowOutletId;
      const responseRequestPending = await getCheckPendingRequestRetailerEdit(workflow_object_id);

      console.log("Pending Request Check Retailer Edit", responseRequestPending);
    
      if(responseRequestPending.pending_request_exist === false)
      {
        setUpdateModalOpen(true);
        setLoading(false);
      }
      else if(responseRequestPending.pending_request_exist === true)
      {
        setIsRetailerRequestPending(true);
        setCountRetailerRequestPending(responseRequestPending.count_pending_request);
        setShowPopup(true);
        setLoading(false);
      }
      
    } catch (error) {
      console.log("Error fetching request data:", error);
    }
  };

  const handleCardOpenAndClose = () => {
    setExpand(!expand)
  }

  const regionName = (region || {}).name;
  const cityName = (city || {}).name;
  const stateName = (state || {}).name;
  const addressElements = [
    address,
    regionName,
    cityName,
    stateName,
  ].filter((element) => Boolean(element));

  const showUpdateStatus = [
    "calling-executive-workflow-permission",
    "qc-executive-workflow-permission",
    "qc-manager-workflow-permission",
  ].includes(workflow_permission);
  console.log("checkkkK",JSON.stringify (saveClickedData))
  console.log("checkkkK222",saveClickedData)
  const enbaleUpdateStatusWithoutEditable =
    WORKFLOW_KEYS.CLIENT_MANAGER === workflow_permission ||
    (related_workflow_object || {}).type_of_calling === "intimation";

  const isCallingExecutive =
    WORKFLOW_KEYS.CALLING_EXECUTIVE === workflow_permission;

    const showSimilarOutlet =
    subProjectType==='payout_cycle'||subProjectType==='kyc'?true:false;

  const uploadCallingTrackerDetails = () => {
    triggerSimpleAjax(
      GET_OUTLET_GROUP_SAVE,
      "POST",
      {
        object_type: "workflow_calling_tracker",
        data_to_create: null,
        data_to_update: selectedCallingTrackerReasonDetails,
      },
      (response) => {
        resetCallingtrackerResonDetails({});
      },
      (error) => {
        console.log(error);
      }
    );
  };
  useEffect(() => {
    // if (showSimilarOutlet) {
    
      
      triggerSimpleAjax(
        GET_SIMILAR_OUTLETS_ACTIONS(id),
        "GET",
        {},
        (response) => {
        
          if(response===[]){
            setSimilarOutletData(null)
          }else{       
            setSimilarOutletData(response)
          }     
        },
        (err) => {
          console.log(err)
        })

    // }
  }, []);
  useEffect(() => {
    if (showUpdateStatus || enbaleUpdateStatusWithoutEditable) {
      getOutletActions()
        .then((res) => {
          const {
            allowed_user_actions_config,
            allowed_user_special_actions_config,
          } = res;
          setWorkflowObjectActionConfig(res);
          const actions = {
            allowed_user_actions_config: Array.isArray(
              allowed_user_actions_config
            )
              ? allowed_user_actions_config[0]
              : allowed_user_actions_config,
            allowed_user_special_actions_config:
              allowed_user_special_actions_config || {},
          };
          const optionList = getActionList({
            actions,
            selectedOutletDetails,
            checkSpecialAction: false,
          });
          setActionList(optionList);
          updateActionListAvailable(!!optionList.length);
        })
        .catch((error) => console.log(error));
    }
  }, [showUpdateStatus, enbaleUpdateStatusWithoutEditable]);

  const addressText = addressElements.reduce(
    (acc, text, index) =>
      text
        ? `${acc}${text}${index !== addressElements.length - 1 ? "," : ""}`
        : acc,
    ""
  );

  const formatDate = (date) => {
    let travelTime=moment(date).add({hours:5, minutes: 30}).format('DD-MMM-YYYY hh:mm A')
    
    let tmp_date = travelTime ? moment(travelTime).format("DD-MMM-YYYY hh:mm A")
      .split(" ") : "N/A"
    if (tmp_date === "N/A") {
      return "N/A"
    }
    return `${tmp_date[0].replace("-", " ")} | ${tmp_date.slice(1)} `.replace(",", " ")
  }
  const formatDateUpdatedTime = (date) => {
    let tmp_date = date ? moment(date).format("DD-MMM-YYYY hh:mm A")
      .split(" ") : "N/A"
    if (tmp_date === "N/A") {
      return "N/A"
    }
    return `${tmp_date[0].replace("-", " ")} | ${tmp_date.slice(1)} `.replace(",", " ")
  }

  // const MilliToMinSec = (millis) => {
  //   var minutes = Math.floor(millis / 60000);
  //   var seconds = ((millis % 60000) / 1000).toFixed(0);
  //   return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  // }

  const handlePlural = (val, string) => {
    return (val === 1 ? `${val} ${string}` : `${val} ${string}s`)
  }

  console.log("created",created, formatDate(created))
  console.log("created1",field_survey_start_datetime,formatDate(field_survey_start_datetime) )
  console.log("created2",field_survey_end_datetime,formatDate(field_survey_end_datetime))

  function msToDuration(ms) {
    const days = Math.floor(ms / (1000 * 60 * 60 * 24));
    const hours = Math.floor((ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);
    if (days === 0 && hours === 0 && minutes === 0) {
      return `${handlePlural(seconds, 'second')}`
    } else if (days === 0 && hours === 0) {
      return `${handlePlural(minutes, 'minute')}`
    } else if (days === 0) {
      return `${handlePlural(hours, 'hour')} ${handlePlural(minutes, 'minute')}`
    } else {
      return `${handlePlural(days, 'day')} ${handlePlural(hours, 'hour')} ${handlePlural(minutes, 'minute')}`
    }
  }

  const duration = (startDate, endDate) => {
    startDate = new Date(startDate)
    endDate = new Date(endDate)
    let duration = "N/A";
    if (startDate && endDate) {
      duration = (endDate.getTime() - startDate.getTime());
      return msToDuration(duration)
    }
    return duration
  }

  const handleMetaEdit = (value) => {
    triggerSimpleAjax(
      ACCEPT_OR_REJECT_FIELD_APP_CHANGES(slug),
      "POST",
      {
        "action_to_perform": value
      },
      (res) => {
        window.location.reload()
      },
    )
  }
  const {
    assigned_field_manager,
    assigned_qc_manager,
    assigned_calling_executive,
    assigned_field_auditor,
    assigned_field_coordinator,
    assigned_iftu_executive,
    assigned_qc_executive,
    assigned_calling_manager,
    field_audit_end_date,
    field_audit_start_date,
    overall_remarks,
    rsa_verification_status,
    rsa_photo_proofs,
    other_config,
    workflow_state,
    client_given_phone_number,
    field_survey_mobile_version_number
  } = related_workflow_object || {};

  const selectedCallingTrackerReasonDetailsKeys = Object.keys(
    selectedCallingTrackerReasonDetails
  );

  const isCallingTrackerDetailsAvailable = selectedCallingTrackerReasonDetailsKeys.length
    ? !!selectedCallingTrackerReasonDetailsKeys.filter((key) =>
      selectedCallingTrackerReasonDetails[key].reason
        .toLowerCase()
        .includes("successful")
    ).length ||
    selectedCallingTrackerReasonDetailsKeys.length ===
    callingTrackerList.length
    : false;
  const enableCheckForEmptyTracker = callingTrackerList.length
    ? isCallingTrackerDetailsAvailable
    : true;
  let rsa_photo_proof =
    rsa_photo_proofs
      ? rsa_photo_proofs.map((item, inx) => {
        return item.image;
      })
      : [];


  const disableButton = saveClickedData ? !workflowStateChanged
    ? !enbaleUpdateStatusWithoutEditable
      ? actionList?.length || Object.values(saveClickedData)?.length
        ? Object.values(saveClickedData)?.length === question_groups?.length
          ? Object.values(saveClickedData).filter((data) => !Boolean(data))
            ?.length
          : true
        : true
      : false
    : true : null;

  const EditElement = ({name, pkey, previous_key}) => (<EditRowWrapper>
    <p>{name}</p>
    <p>{previous_key}</p>
    <p>{pkey}</p>
  </EditRowWrapper>)

  return (
    <Fragment>
      <OutletMetaWrapper expand={expand}>
        <RowWrapper>
          <RowBlock>
            <Block>
              <Title>Outlet Details</Title>
              <p>
                <b>Outlet Name: </b> {name}
              </p>
              <p>
                <b>RSID: </b> {rsid}
              </p>
              {workflow_state ? <p>
                <b>Workflow Status: </b> {workflow_state.split('_').map(a => capitializeFirstChar(a)).join(' ')}
              </p> : null}
              <p>
                <b>Outlet Id: </b> {outlet_id}
              </p>
              <Location>
                {outletName}

                <p>
                  <LocationOnIcon style={{color: blue300}}/>
                  {previous_address ? previous_address : addressText} {zip_code}
                </p>
                {/*{previous_address && (*/}
                {/*  <>*/}
                {/*    <p><b> Edited From </b></p>*/}
                {/*    <LocationOnIcon style={{color: blue300}}/>*/}
                {/*    {previous_address}*/}
                {/*  </>*/}
                {/*)}*/}

              </Location>
              <p>
                <b>Distributor GST Number: </b> {distributor_gst_number}
              </p>
              <Title>Dealer Board</Title>
              {(banner_image || {}).image ? (
                <ImageBlock
                  image={(banner_image || {}).image}
                  onClick={() => setGalleryImage([banner_image.image])}
                />
              ) : (
                "N/A"
              )}
            </Block>
            <Column>
              <Block>
                <Title>Survey Start date</Title>
                {/* {formatDate(field_survey_start_datetime)} */}
                {moment(field_survey_start_datetime).format('DD MMM-YYYY | h:mm:ss A')}
              </Block>
              <Block>
                <Title>Survey End date</Title>
                {/* {formatDate(field_survey_end_datetime)} */}
                {moment(field_survey_end_datetime).format('DD MMM-YYYY | h:mm:ss A')}
              </Block>
              <Block>
                <Title>Start Location</Title>
                {
                  field_survey_start_latitude && field_survey_start_longitude ?
                    (
                      <Fragment>
                        <span>{field_survey_start_latitude}, {field_survey_start_longitude} </span>
                      </Fragment>
                    ) :
                    "N/A"
                }
              </Block>
              <Block>
                <Title>End Location</Title>
                {field_survey_end_latitude && field_survey_end_longitude ?
                  (
                    <Fragment>
                      <span>{field_survey_end_latitude}, {field_survey_end_longitude} </span>
                    </Fragment>
                  ) :
                  "N/A"
                }
              </Block>
              {isCallingExecutive && (
                <Block>
                  <Title>QC Status</Title>
                  <>
                    {related_workflow_object.calling_inbound_source_differentiation || "N/A"}
                  </>
                </Block>
              )}
              <Block>
                <Title>Duration </Title>
                {
                  field_survey_start_datetime && field_survey_end_datetime ?
                    duration(field_survey_start_datetime, field_survey_end_datetime) : "N/A"
                }
              </Block>
              <Block>
                <Title>Location Accuracy </Title>
                {
                  field_survey_start_location_accuracy ?
                    field_survey_start_location_accuracy : "N/A"
                }
              </Block>
              <Block>
                <Title>Upload Date & Time</Title>
                {formatDateUpdatedTime(created)}
              </Block>
              <Block wordBreak="word-break">
                <Title>RSA Verification Status</Title>
                <RowText>
                  {capitializeFirstChar(
                    (rsa_verification_status || "").replace(/_/g, " ")
                  ) || " N/A"}
                </RowText>
                {(rsa_photo_proof || []).length ? (
                  <LinkText onClick={() => setGalleryImage([rsa_photo_proof])}>
                    View photos
                  </LinkText>
                ) : null}
              </Block>
            </Column>
            <Column>
              <Block>
                <Title>Pre Survey Remark</Title>
                {survey_not_done_notes_by_field_auditor || "-"}
              </Block>
              <Block>
                <Title>Notes By Auditor</Title>
                {notes_by_field_auditor ? (
                  notes_by_field_auditor.length > 22 ?
                    (
                      <>
                        {notes_by_field_auditor.substring(20, 0) + "..."}
                        <LinkText data-tip data-for={`read-more-notes-auditor`}>
                          {"  "}
                          Read More
                        </LinkText>
                        <ReactTooltip id={`read-more-notes-auditor`} place={"bottom"} type={"dark"}>
                          <Block blockWidth={"250px"}>
                            {notes_by_field_auditor}
                          </Block>
                        </ReactTooltip>
                      </>
                    ) : notes_by_field_auditor
                ) : "-"
                }
              </Block>
              <Block>
                <Title>Notes</Title>
                {qc_display_notes || "-"}
              </Block>
              <Block>
                <Title>Calling Status</Title>
                {related_workflow_object.calling_display_notes || "-"}
              </Block>
              <Block>
                <Title>Calling Notes</Title>
                {calling_remarks || "-"}
              </Block>
              <Block>
                <Title>Client Notes</Title>
                {client_remarks || "-"}
              </Block>
              <Block>
                <Title>Source:</Title>
                {capitializeFirstChar((inbound_source || "").replace(/_/g, " "))}
              </Block>
              <Block>
                <Title>Phone Number 1:</Title>
                {previous_phone_number1 ? previous_phone_number1 : phone_number1 || "N/A"}
                {/*{previous_phone_number1 && (*/}
                {/*  <>*/}
                {/*    <p><b> Edited From </b></p>*/}
                {/*    {previous_phone_number1}*/}
                {/*  </>*/}
                {/*)}*/}
              </Block>
              <Block>
                <Title>Phone Number 2:</Title>
                {previous_phone_number2 ? previous_phone_number2 : phone_number2 || "N/A"}
                {/*{previous_phone_number2 && (*/}
                {/*  <>*/}
                {/*    <p><b> Edited From </b></p>*/}
                {/*    {previous_phone_number2}*/}
                {/*  </>*/}
                {/*)}*/}
              </Block>
              <Block>
                <Title>Client Phone Number</Title>
                {client_given_phone_number || "N/A"}
              </Block>

            </Column>
            <Block>
              <Title>Associated By:</Title>
              <RowText>
                <span>Field Coordinator: </span>
                <span>{(assigned_field_coordinator || {}).name || "N/A"}</span>
              </RowText>
              <RowText>
                <span>Field Auditor : </span>
                <span>{(assigned_field_auditor || {}).name || "N/A"}
                  <span>{(assigned_field_auditor || {}).phone_number ? `[${assigned_field_auditor.phone_number}]` : ""}</span> </span>
              </RowText>

              <RowText>
                <span>QC Executive: </span>
                <span>
                {(assigned_qc_executive || {}).name || "N/A"}
              </span>
              </RowText>

              <RowText>
                <span>QC Manager: </span>
                <span>{(assigned_qc_manager || {}).name || "N/A"}</span>
              </RowText>
              {
                assigned_calling_manager ?
                  (
                    <RowText>
                      <span>Calling Manager: </span>
                      <span>{(assigned_calling_manager || {}).name || "N/A"}</span>
                    </RowText>
                  ) :
                  null
              }

              <RowText>
                <span>Field Manager:</span>
                <span>{(assigned_field_manager || {}).name || "N/A"}</span>
              </RowText>

              <RowText>
                <span>Version Number:</span>
                <span>{field_survey_mobile_version_number || "N/A"}</span>
              </RowText>

              {assigned_calling_executive ?
                (
                  <RowText>
                    <span>Calling Executive:</span>
                    <span>
                    {(assigned_calling_executive || {}).name || "N/A"}
                  </span>
                  </RowText>
                ) :
                null
              }
              <RowText>
                <span>Bulk Uploaded by:</span>
                <span>{bulk_created_by|| "N/A"}</span>
              </RowText>
              <RowText>
                <span>Bulk Approved by:</span>
                <span>{bulk_approved_by|| "N/A"}</span>
               </RowText>
            </Block>
          </RowBlock>
          <Fragment>
           
           <Block>
           {showUpdateStatus || enbaleUpdateStatusWithoutEditable ? (
              <ToolTip
               content={actionList.length ? "" : " No action possible!"}
               align="top center"
               padding="4px"
               contentWidth="150px"
               icon={
                 <Button
                   size="large"
                   bgColor={green300}
                   fontStyle={{ fontSize: "16px" }}
                   margin="0 0 12px"
                  //  onClick={() => setUpdateModalOpen(true)}
                  onClick={checkPendingRequest}
                   isDisabled={
                     actionList.length
                       ? isCallingExecutive
                         ? enableCheckForEmptyTracker
                           ? disableButton
                           : true
                         : disableButton
                       : true
                   }
                 >
                   Update Status
                 </Button>
               }
             />   ) : null}

             {enbaleUpdateStatusWithoutEditable || showUpdateStatus && status ? (
               <StatusTagStyle color={black700} marginLeft="7px" size="small">
                 {status}
               </StatusTagStyle>
             ) : null}

          {showSimilarOutlet ? (
              <ToolTip
               // content={actionList.length ? "" : " No action possible!"}
               align="top center"
               padding="4px"
               contentWidth="110px"
               icon={
                 <Button
                   size="large"
                   bgColor={white}
                   fontStyle={{ fontSize: "16px" }}
                   border={`1px solid ${green300}`}
                   color={green300}
                   // margin="0 0 5px"
                 
                   onClick={() => setSimilarOutletModal(true)}
                  
                 >
                   Similar Outlets - {similarOutletData.length}
                 </Button>
               }
             />   ) : null}
           </Block>
      
         
       </Fragment>

          <div
            style={{
              'marginLeft': 'auto',
              'display': 'flex',
              'flex-flow': 'column',
              'justify-content': 'space-between',
              'align-items': 'end'
            }}
          >
          <span
            style={{
              'cursor': 'pointer',
            }}
            onClick={handleCardOpenAndClose}
          >
          {expand ? <CloseIcon/> : <MenuIcon/>}
        </span>

          </div>
        </RowWrapper>

        {/* Edited Items  */}
        {(previous_phone_number2 || previous_phone_number1 || previous_address) && (
          <div>
            <h3>Edited Items</h3>
            <EditRowWrapper>
              <h3>Field</h3>
              <h3>Edited From</h3>
              <h3>Edited To</h3>
            </EditRowWrapper>
            {previous_address && (<EditElement name="Address" pkey={addressText} previous_key={previous_address}/>)}
            {previous_phone_number1 && (
              <EditElement name="Phone Number 1" pkey={phone_number1} previous_key={previous_phone_number1}/>)}
            {previous_phone_number2 && (
              <EditElement name="Phone Number 2" pkey={phone_number2} previous_key={previous_phone_number2}/>)}

            {(previous_phone_number2 || previous_phone_number1 || previous_address) && (
              <div
                style={{
                  'display': 'flex',
                  'justify-content': 'end'
                }}
              >
                <Button
                  size="medium"
                  type="submit"
                  bgColor={cinnabar300}
                  onClick={() => handleMetaEdit("reject")}
                >
                  Reject
                </Button>
                <Button
                  size="medium"
                  type="submit"
                  bgColor={green300}
                  onClick={() => handleMetaEdit("accept")}
                  margin="0"
                >
                  Accept
                </Button>
              </div>
            )}
          </div>
        )}

      </OutletMetaWrapper>
      {openUpdateModal ? (
        <UpdateStatusModal
          setModalOpen={openUpdateModal}
          modalAction={() => setUpdateModalOpen(!openUpdateModal)}
          getOutletDetails={getOutletDetails}
          history={history}
          actionList={actionList}
          workflow_permission={workflow_permission}
          selectedOutletRows={[
            (selectedOutletDetails[0] || {}).workflowOutletId,
          ]}
          saveClickedData={saveClickedData}
          selectedCallingTrackerReasonDetails={selectedCallingTrackerReasonDetails}
          setSaveClickedData={setSaveClickedData}
          setWorkflowStateChanged={setWorkflowStateChanged}
          callback={isCallingExecutive ? uploadCallingTrackerDetails : null}
          rsidList={similarOutletData}
        />
      ) : null}
       {openSimilarOutletModal ? (
        <SimilarOutletModal
          setModalOpen={openUpdateModal}
          modalAction={() => setSimilarOutletModal(!openSimilarOutletModal)}
          getOutletDetails={getOutletDetails}
          history={history}
          similarOutletData={similarOutletData}
          actionList={actionList}
          workflow_permission={workflow_permission}
          selectedOutletRows={[
            (selectedOutletDetails[0] || {}).workflowOutletId,
          ]}
          saveClickedData={saveClickedData}
          selectedCallingTrackerReasonDetails={
            selectedCallingTrackerReasonDetails
          }
          setSaveClickedData={setSaveClickedData}
          setWorkflowStateChanged={setWorkflowStateChanged}
          callback={isCallingExecutive ? uploadCallingTrackerDetails : null}
        />
      ) : null}

      {isRetailerRequestPending && showPopup &&( 
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              textAlign: 'center',
              width: '35%',
              height: '35%',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              fontSize: '16px',
              alignItems: 'center',
            }}
          >
             <span
               style={{
                fontSize: '2.5rem',
                color: '#aaa',
                marginLeft: '95%',
                cursor: 'pointer',
              }}
              onClick={() => {
                console.log("Close button clicked");
                setShowPopup(false);
              }}
            >
              &times;
            </span>
            <Error style={{
              fontSize: '3rem',
              paddingTop: '2.5%'
            }}/>
            <p style={{
              paddingTop: '1%'
            }}>
              There are {countRetailerRequestPending} Pending Retailer Request!
            </p>
          </div>
        </div>
      )}

      {galleryImage.length ? (
        <ImageGallery
          images={galleryImage}
          setShowGallery={() => setGalleryImage([])}
        />
      ) : null}

      {loading ? (
        <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)', 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}
      >
          <div style={{
            border: '4px solid rgba(255, 255, 255, 0.3)',
            borderTop: '4px solid #3498db', 
            borderRadius: '50%',
            width: '50px', 
            height: '50px', 
            animation: 'spin 1s linear infinite',
          }}/>
        </div> 
        ) : null}

    </Fragment>
  );
};

