import { triggerSimpleAjax } from '../../helpers/httpHelper';
import { GET_OUTLETS } from '../../helpers/constants/apiUrl';

export default function getOutlets(req = '', workflowBased, isStatus) {
    if (req.includes('?show_only_special_action_states=false')) {
        req = req.replace('?show_only_special_action_states=false', '');
    } else if (req.includes('&show_only_special_action_states=false')) {
        req = req.replace('&show_only_special_action_states=false', '');
    }

    let reqString = workflowBased ? `?workflow_based=true${req.replace('?', '&')}` : req;

    // Extract all existing status values
    const statusMatches = reqString.match(/[?&]status=([^&]*)/g);
    console.log("statusMatches",statusMatches);
    let existingStatuses = statusMatches ? statusMatches.map(match => match.split('=')[1]) : [];
    console.log("existingStatuses",existingStatuses);


    if (isStatus) {
        if (existingStatuses.length === 0) {
            // Default to `active` if no status is found
            existingStatuses.push('active');
        } else if (!existingStatuses.includes('active') && !existingStatuses.includes('inactive')) {
            // Ensure only valid statuses are included
            existingStatuses = ['active'];
        }
    }

    // Remove any existing status parameters and append the correct values
    reqString = reqString.replace(/[?&]status=[^&]*/g, '');
    if (existingStatuses.length > 0) {
        reqString += `&status=${existingStatuses.join('&status=')}`;
    }

    // Ensure `include_temp=yes` is always included
    if (!reqString.includes('include_temp=yes')) {
        reqString += `&include_temp=yes`;
    }

    return new Promise((res, rej) => {
        triggerSimpleAjax(
            `${GET_OUTLETS}${reqString}`,
            'GET',
            {},
            (response) => {
                res(response);
            },
            (error) => {
                rej(error);
            }
        );
    });
}
