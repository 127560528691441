
import React, { useState, useEffect, Fragment } from "react";
import ImageZoom from "../../../components/ImageZoom";
import getOutletGroupDetails from "../../../actions/outlet/getOutletGroupDetails";

import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Modal } from "../../../../src/components/Modal";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { FormRow } from "../components/FormRow";
import { DashboardTextBox } from "../components/DashboardTexBox";
import {
  VerticalNavItem,
  VerticalNav,
  PrevArrow,
  NextArrow,
  ContentWrapper,
  Title,
} from "./outlet.styles";
import Loader from "../../../components/Loader";
import {
  black100,
  green300,
  black600,
  black200,
  black300,
  curiousBlue300,
  white,
  black700,
  cinnabar300,
} from "../../../theme/colors";
import { triggerSimpleAjax } from "../../../helpers/httpHelper";
import {
  GET_OUTLET_GROUP,
  GET_OUTLET_GROUP_SAVE,
  IFSC_VERIFICATION,
  PAN_AND_GST_VERIFICATION, UPI_VERIFICATION,
  YES_BANK_ACCOUNT_VERIFICATION,
} from "../../../helpers/constants/apiUrl";
import { Button } from "../../../components/Button";
import styled from "styled-components";
import { outletQuestionGroupSaveAction } from "./helper/outletQuestionGroupSaveAction";
import _cloneDeep from "lodash/cloneDeep";
import { baseCaption, defaultFont } from "../../../theme/typography";
import { getDayOrNumberSuffix } from "../../../helpers/getDayOrNumbersuffix";
import { WORKFLOW_KEYS } from "../../../helpers/constants/workflowKeys";
import { SelectFilter } from "../components/SelectFilter";
import capitializeFirstChar from "../../../helpers/utils/capitializeFirstChar";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import CloseIcon from "@material-ui/icons/Close";
import { semiBold } from "../../../theme/typeface";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import triggerNotification from "../../../actions/triggerNotification";
import ReactTooltip from "react-tooltip";
import SkuTableOutlet from "./sku-table-outlet";
import { Dashboard } from "@material-ui/icons";
import ImageIcon from "@material-ui/icons/Image";
import { DASHBOARDROUTES } from "../../../helpers/constants/routes";
import { useHistory } from "react-router-dom"
import CheckBox from "../../../components/ui/CheckBox";
import CollectedBills from "./CollectedBills";
import {Error} from "@material-ui/icons";

const sliderRef = React.createRef();


const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 82%;
`;

const TextWrapper = styled.p`
  ${baseCaption}
  color:${black600};
  margin: 8px 0 0;
`;
const VerificationText = styled.div`
  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    vertical-align: middle;
    color: ${(props) => (props.isVerified ? cinnabar300 : green300)};
  }
  margin-top: 3px;
  display: flex;
  flex-direction: row;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuestionContainer = styled.div`
  overflow-y: scroll;
  max-height: 600px;
  min-width: 350px;
  padding-bottom: 20px;
`;

const TotalImageCount = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: #4770c1;
  color: #ffffff;
  padding: ${props => props.clientScreen ? '6px 12px' : '9px 12px'};
  font-weight: 500;
  font-size:${props => props.clientScreen ? '13px' : '14px'};
  margin-bottom: 30px;
  cursor: pointer;
`;

const getStringListArr = (string) => {
  const hypenSplitArr = []
  const spaceSplitArr = string.split(" ")
  spaceSplitArr.map(string => {
    const hypenSplit = string.split("-")
    if (hypenSplit.length > 0) {
      hypenSplitArr.push(...hypenSplit)
    }
  })

  return hypenSplitArr
}
const getIsKeyInString = (string, key) => {
  let isPresent = false;
  if (string) {
    const stringArr = getStringListArr(string)
    stringArr.map(strVal => {
      if (strVal.toUpperCase() === key.toUpperCase()) {
        isPresent = true
      }
    })
  }
  return isPresent
}



const OutletSurvey = ({
  question_groups,
  question_and_answers,
  outletDetails,
  sub_project,
  totalImages = [],
  setSaveClickedData,
  saveClickedData,
  workflow_permission,
  isUpdateActionListAvailable = true,
  workflowStateChanged,
  triggerNotification,
  workflow_state,
}) => {
  const history = useHistory()
  const [ifscVerificationStatus, setIfscVerificationStatus] = useState("");
  const [gstVerificationStatus, setGstVerificationStatus] = useState("");
  const [panVerificationStatus, setPanVerificationStatus] = useState("");
  const [accountVerificationStatus, setAccountVerificationStatus] = useState("");
  const [upiVerificationStatus, setUPIVerificationStatus] = useState("");
  const [subIfscVerification, setSubIfscVerification] = useState(false)
  const [ifscCode, setIfscCode] = useState("");
  const [pan, setPan] = useState("");
  const [gst, setGst] = useState("");
  const [loaders, setLoaders] = useState({});
  const [activeGroupIndex, setActiveGroupIndex] = useState(0);
  const [activeQuestionGroupIndex, setActiveQuestionGroupIndex] = useState(null);
  const [outletGroupDetails, setOutletGroupDetails] = useState([]);
  const [questionGroupImages, setQuestionGroupImages] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const [requestInProcess, setRequestInProcess] = useState(false);
  const [outletGroupDetailsCopy, setOutletGroupDetailsCopy] = useState([]);
  const [errorDetails, setErrorDetails] = useState({});
  const [validationChoices, setValidationChoices] = useState({});
  const [isSliderComplete, setIsSliderComplete] = useState(false);
  const [isGstLegalTradeFound, setIsGstLegalTradeFound] = useState(false);

  const [selectedValidationChoice, setSelectedValidationChoice] = useState(
    null
  );
  const isSkuGroup = Array.isArray(outletDetails.question_groups) && outletDetails.question_groups.length !== 0 && outletDetails.question_groups[activeGroupIndex].type === "sku_group"
  const workflowId = (outletDetails.related_workflow_object || {}).id;

  const [isModalOpen, setIsModalOpen] = useState(false); 

  const openModal = () => {
    setIsModalOpen(true);
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  const getCurrentSlug = (index) => {
    const data = outletDetails.question_groups && outletDetails.question_groups[index]
    return data?.slug
  }
  const getGroupDetails = (index) => {
    const workflowId = (outletDetails.related_workflow_object || {}).id;
    const data =
      outletDetails.question_groups && outletDetails.question_groups[index];
    if (data) {
      const req = {
        workflow_object: outletDetails.workflow_object,
        question_group: data.id,
      };
      console.log("reeeeeeqqq",req.question_group);

      getOutletGroupDetails(req).then((res) => {
        triggerSimpleAjax(
          `${GET_OUTLET_GROUP}${data.slug}/answer-linked-images/?related_multiple_images_answer_input__workflow_object=${workflowId}&related_single_image_answer_input__workflow_object=${workflowId}`,
          "GET",
          {},
          (response) => {
            // Math.random is added to prevent image load from cache
            const images = response.map((imageDetails) => `${imageDetails.image}?${Math.random()}`);
            setQuestionGroupImages(images);
          },
          (error) => { }
        );
        setOutletGroupDetails(res.results);

        setOutletGroupDetailsCopy(_cloneDeep(res.results));
        setLoader(false);

        if (
          !(res.results || []).length ||
          !(res.results || []).filter(
            (question) => !(question.type || "").includes("image")
          ).length
        ) {
          // Check if isSliderComplete is true
          if (isSliderComplete) {
            // If isSliderComplete is true, set question_group ID 3866 to true
            if (req.question_group === 3866) {
              setSaveClickedData({
                ...saveClickedData,
                [activeGroupIndex]: true,
              });
            }
          } else {
            // If isSliderComplete is false, proceed as before
            if (req.question_group !== 3866) {
              setSaveClickedData({
                ...saveClickedData,
                [activeGroupIndex]: true,
              });
            }
          }
        }
      });
    }  else {
      setLoader(false);
      setOutletGroupDetails({});
    }

    console.log("isSLiderComplete111",isSliderComplete)
  };

  useEffect(() => {
    if(isSliderComplete===true)
      setSaveClickedData({
        ...saveClickedData,
        [activeGroupIndex]: true,
      });
    
  }, [isSliderComplete]);


  const getGroupDetailsWrapper = (activeGroup) => {
    if (activeGroup && question_and_answers?.[activeGroup.group_index]) {
      getGroupDetailsBasedOnId(activeGroup)
    } else {
      getGroupDetails(activeGroupIndex)
    }
  }
  const getGroupDetailsBasedOnId = (activeGroup) => {
    if (activeGroup) {
      setLoader(true)
      setOutletGroupDetails(Object.assign([], question_and_answers[activeGroup.group_index]))

      const data =
        outletDetails.question_groups && outletDetails.question_groups[activeGroup?.position_index]


      const workflowId = (outletDetails.related_workflow_object || {}).id;

      triggerSimpleAjax(
        `${GET_OUTLET_GROUP}${data.slug}/answer-linked-images/?related_multiple_images_answer_input__workflow_object=${workflowId}&related_single_image_answer_input__workflow_object=${workflowId}`,
        "GET",
        {},
        (response) => {
          // Math.random is added to prevent image load from cache
          const images = response.map((imageDetails) => `${imageDetails.image}?${Math.random()}`);
          setQuestionGroupImages(images);
        },
        (error) => { }
      );

      setLoader(false);
    }

  }

  useEffect(() => {
    if (question_groups && Array.isArray(question_groups)) {
      setActiveQuestionGroupIndex({ group_index: question_groups[0]?.id, position_index: 0 })
    }
  }, [])

  useEffect(() => {
    if (activeQuestionGroupIndex) {
      setLoader(true);

      getGroupDetailsWrapper(activeQuestionGroupIndex)

   

      setIfscVerificationStatus(
        outletDetails.related_workflow_object.ifsc_code_verification_status
      );
      setGstVerificationStatus(outletDetails.gst_number_verification_status);
      setPanVerificationStatus(outletDetails.pan_number_verification_status);
    }
  }, [activeQuestionGroupIndex])

  // useEffect(() => {
  //   // setLoader(true);
  //   if (
  //     workflow_permission === WORKFLOW_KEYS.QC_MANAGER ||
  //     workflow_permission === WORKFLOW_KEYS.QC_EXECUTIVE
  //   ) {
  //     setEnableValidationChoices(true);
  //   }
  //   // getGroupDetails(activeGroupIndex);
  //   setIfscVerificationStatus(
  //     outletDetails.related_workflow_object.ifsc_code_verification_status
  //   );
  //   setGstVerificationStatus(outletDetails.gst_number_verification_status);
  //   setPanVerificationStatus(outletDetails.pan_number_verification_status);
  // }, [activeGroupIndex]);

  const isQuestionGroupOnState = (questionIndex) => {
    return question_and_answers?.[activeQuestionGroupIndex?.group_index]?.[questionIndex]
  }
  const onChange = ({ e, questionIndex, type }) => {
    if (
      enableEditAnswers &&
      (type === "yes_or_no" || type === "single_choice"||type === "dropdown") &&
      outletGroupDetails[questionIndex]["related_answers"].length > 0
    ) {
      outletGroupDetails[questionIndex]["related_answers"][0]["single_input"] =
        e.value;
      if (isQuestionGroupOnState(questionIndex)) {
        question_and_answers[activeQuestionGroupIndex?.group_index][questionIndex]["related_answers"][0]["single_input"] = e.value
      }
      // if(question_and_answers[activeQuestionGroupIndex?.group_index][questionIndex])
    } else if (enableEditAnswers && type === "multiple_choice" &&
    outletGroupDetails[questionIndex]["related_answers"].length > 0
  ) {
    let multiple_array=[]
    if(e != null){
      for(let i=0;i<e.length;i++){
        multiple_array.push(e[i].value)
       }
    }
        outletGroupDetails[questionIndex]["related_answers"][0]["multiple_answers_input"] = multiple_array
        if (isQuestionGroupOnState(questionIndex)) {
          question_and_answers[activeQuestionGroupIndex?.group_index][questionIndex]["related_answers"][0]["multiple_answers_input"] =multiple_array
        }
  
      
    }

    else {
      outletGroupDetails[questionIndex]["related_answers"][0][e.target.name] =
        e.target.value;

      if (isQuestionGroupOnState(questionIndex)) {
        question_and_answers[activeQuestionGroupIndex?.group_index][questionIndex]["related_answers"][0][e.target.name] = e.target.value
      }
    }
    outletGroupDetails[questionIndex]["related_answers"][0].is_edited = true
    setOutletGroupDetails(Object.assign([], outletGroupDetails));
  };

  const checkValidation = (name, type) => {
    if (name.toUpperCase().includes("IFSC")) {
      return ifscVerificationStatus === "not_verified_yet" ? true : false;
    } else if (name.toUpperCase().includes("PAN")) {
      return panVerificationStatus === "not_verified_yet" ? true : false;
    } else if (name.toUpperCase().includes("GST")) {
      return gstVerificationStatus === "not_verified_yet" ? true : false;
    } else if (type === "account_number") {
      return accountVerificationStatus === "not_verified_yet" ? true : false;
    } else if (name.toUpperCase().includes("UPI")) {
      return upiVerificationStatus === "not_verified_yet";
    }
  };

  const verifyDetails = (name, answerText, questionIndex, type) => {

    if (name.toUpperCase().includes("IFSC")) {
      const code = ifscCode ? ifscCode : answerText;
      setLoaders({
        ...loaders,
        [name]: true
      })
      ifscVerficationCheck(code, name, questionIndex);
    } else if (name.toUpperCase().includes("PAN")) {
      const code = pan !== "" ? pan : answerText;
      setLoaders({
        ...loaders,
        [name]: true
      })
      panAndGSTVerificationCheck("PAN", code, name, questionIndex);
    } else if (name.toUpperCase().includes("GST")) {
      let code = gst === "" ? answerText : gst;
      setLoaders({
        ...loaders,
        [name]: true
      })
      panAndGSTVerificationCheck("GST", code, name, questionIndex);
    } else if (type === "account_number") {
      setLoaders({
        ...loaders,
        [name]: true
      })

      yesBankAccountVerification(name, questionIndex);
    } else if (name.toUpperCase().includes("UPI")) {
      setLoaders({
        ...loaders,
        [name]: true
      })

      upiVerification(name, questionIndex)
    }
  };

  const upiVerification = (name, questionIndex) => {
    const related_answers = outletGroupDetails[questionIndex]["related_answers"][0]
    const reqBody = {
      upi_id: related_answers.single_input,
      other_upi_account_name: related_answers.other_upi_account_name
    }

    triggerSimpleAjax(
      UPI_VERIFICATION,
      "POST",
      reqBody,
      (response) => {
        triggerNotification({
          messageText: "Validated Successfully !1",
          status: "SUCCESS"
        })
        setLoaders({
          ...loaders,
          [name]: false
        })
        outletGroupDetails[questionIndex].related_answers[0].is_edited = true;
        outletGroupDetails[questionIndex].related_answers[0].verified = response.is_success;
        outletGroupDetails[questionIndex].related_answers[0].other_upi_account_name = response.name;
        setOutletGroupDetails(Object.assign([], outletGroupDetails));
        setUPIVerificationStatus("verified")
      },
      (error) => {
        triggerNotification({
          messageText: "Validation Failed !!",
          status: "FAILED"
        })
        setLoaders({
          ...loaders,
          [name]: false
        })
      }
    )
  }
  const yesBankAccountVerification = (name, questionIndex) => {
    const related_answers = outletGroupDetails[questionIndex]["related_answers"][0]
    const reqBody = {
      phone_number: related_answers.other_phone_number,
      to_account_number: related_answers.single_input,
      to_account_ifsc_code: related_answers.other_ifsc_code
    }

    triggerSimpleAjax(
      YES_BANK_ACCOUNT_VERIFICATION,
      "POST",
      reqBody,
      (response) => {
        triggerNotification({
          messageText: "Validated Sucessfully !!",
          status: "SUCCESS"
        })
        setLoaders({
          ...loaders,
          [name]: false
        })
        outletGroupDetails[questionIndex].related_answers[0].verified = true
        outletGroupDetails[questionIndex]["related_answers"][0].other_account_holder_name = response.other_account_holder_name || "N/A";
        outletGroupDetails[questionIndex]["related_answers"][0].is_edited = true;
        setOutletGroupDetails(Object.assign([], outletGroupDetails));
        setAccountVerificationStatus("verified")
        onSaveAction(false, true)
      },
      (error) => {
        setLoaders({
          ...loaders,
          [name]: false
        })
        triggerNotification({
          messageText: JSON.stringify(error),
          status: "FAILED",
        });
      }
    )
  }
  const ifscVerficationCheck = (value, name, questionIndex) => {
    triggerSimpleAjax(
      IFSC_VERIFICATION(value),
      "GET",
      {},
      (response) => {
        triggerNotification({
          messageText: `Validated Successfully !!`,
          status: "SUCCESS",
        });
        setLoaders({
          ...loaders,
          [name]: false
        })
        if (name.includes("sub")) {
          outletGroupDetails[questionIndex]["related_answers"][0].verified_secondary = true
          outletGroupDetails[questionIndex]["related_answers"][0].other_branch_name = response.branch || "N/A"
          outletGroupDetails[questionIndex]["related_answers"][0].other_bank_name = response.bank || "N/A"
          setSubIfscVerification(false)
        } else {
          outletGroupDetails[questionIndex].related_answers[0].verified = true
          outletGroupDetails[questionIndex]["related_answers"][0].other_branch_name = response.branch || "N/A"
          outletGroupDetails[questionIndex]["related_answers"][0].other_bank_name = response.bank || "N/A"
          setIfscVerificationStatus("verified");
        }
        outletGroupDetails[questionIndex]["related_answers"][0].is_edited = true
        setOutletGroupDetails(Object.assign([], outletGroupDetails));
        onSaveAction(false, true)
      },
      (error) => {
        setLoaders({
          ...loaders,
          [name]: false
        })
        triggerNotification({
          messageText: `Validation Failed !!`,
          status: "FAILED",
        });
      }
    );
  };
  const panAndGSTVerificationCheck = (name, value, key, questionIndex) => {
    triggerSimpleAjax(
      PAN_AND_GST_VERIFICATION + `?input_type=${name}&input=${value}`,
      "POST",
      {},
      (response) => {
        setLoaders({
          ...loaders,
          [key]: false
        })
        triggerNotification({
          messageText: `Validated Successfully !!`,
          status: "SUCCESS",
        });
        if (name === "GST") {

          outletGroupDetails[questionIndex].related_answers[0].verified = true
          // Setting Trade name and Legal name
          outletGroupDetails[questionIndex]["related_answers"][0].other_gst_trade_name = response.tradeNam || "N/A";
          outletGroupDetails[questionIndex]["related_answers"][0].other_gst_legal_name = response.lgnm || "N/A";
          outletGroupDetails[questionIndex]["related_answers"][0].other_gst_registration_status = response.dty || "N/A";
          outletGroupDetails[questionIndex]["related_answers"][0].is_edited = true
          setOutletGroupDetails(Object.assign([], outletGroupDetails));
          setGstVerificationStatus("verified");
          onSaveAction(false, true)
        } else if (name === "PAN") {

          outletGroupDetails[questionIndex].related_answers[0].verified = true
          // Setting the pan holder name
          outletGroupDetails[questionIndex]["related_answers"][0].other_pan_holder_name = response.name || "N/A"
          outletGroupDetails[questionIndex]["related_answers"][0].is_edited = true
          setOutletGroupDetails(Object.assign([], outletGroupDetails));
          setPanVerificationStatus("verified");
          // onSaveAction(false, true)
        }
      },
      (error) => {
        setLoaders({
          ...loaders,
          [key]: false
        })
        triggerNotification({
          messageText: `Validation Failed !!`,
          status: "FAILED",
        });

        console.log(error);
      }
    );
  };
  const onSaveAction = (sku_request_body = false, isBackgroundRequest = false) => {
    if (activeGroupIndex < question_groups.length - 1) {
      const nextQGroup = question_groups[activeGroupIndex + 1]
      setActiveQuestionGroupIndex({
        group_index: nextQGroup.id,
        position_index: activeGroupIndex + 1
      })
    }
  
    outletQuestionGroupSaveAction({
      outletGroupDetails: outletGroupDetails,
      setRequestInProcess,
      setSaveClickedData,
      activeGroupIndex,
      saveClickedData,
      question_groups,
      setOutletGroupDetails,
      setOutletGroupDetailsCopy,
      setActiveGroupIndex,
      setValidationChoices,
      setErrorDetails: setErrors,
      sku_request_body,
      isBackgroundRequest,
    });
  };

  // Slick Slider Settings


  const NextArrowWrapper = () => (
    <NextArrow
      onClick={() => {
        sliderRef.current.slickNext();
        if (activeQuestionGroupIndex?.position_index < question_groups.length - 1) {
          const nextQGroup = question_groups[activeGroupIndex + 1]
          setActiveGroupIndex(activeGroupIndex + 1);
          setActiveQuestionGroupIndex({
            group_index: nextQGroup.id,
            position_index: activeGroupIndex + 1
          })
        }
      }}
    >
      <ArrowForwardIosIcon />
    </NextArrow>
  );
  const PrevArrowWrapper = () => (
    <PrevArrow
      onClick={() => {
        sliderRef.current.slickPrev();
        if (activeQuestionGroupIndex?.position_index !== 0) {
          const nextQGroup = question_groups[activeGroupIndex + 1]
          setActiveGroupIndex(activeGroupIndex - 1);
          setActiveQuestionGroupIndex({
            group_index: nextQGroup.id,
            position_index: activeGroupIndex - 1
          })
        }
      }}
    >
      <ArrowBackIosIcon />
    </PrevArrow>
  );

  const settings = {
    // variableWidth: true,
    //   centerMode: true,
    //   centerPadding: '0',
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrowWrapper />,
    prevArrow: <PrevArrowWrapper />
  };

  const setErrors = (err) => {
    triggerNotification({
      messageText: JSON.stringify(err),
      status: "FAILED",
    });

    setErrorDetails(err);
  }
  const enableEditAnswers = (
    ([WORKFLOW_KEYS.QC_MANAGER,WORKFLOW_KEYS.QC_EXECUTIVE].includes(workflow_permission) || 
    (workflow_permission === WORKFLOW_KEYS.PROJECT_MANAGER && workflow_state === "qc_accepted" || workflow_state === "wip_client_accepted" || workflow_state === "calling_accepted")) 
    ? ((workflow_permission === WORKFLOW_KEYS.QC_EXECUTIVE && workflow_state !== "qc_pending") 
        ? false 
        : ((!workflowStateChanged) ? (isUpdateActionListAvailable || WORKFLOW_KEYS.PROJECT_MANAGER) : false)) 
    : false
  );
  // const enableEditAnswers = ([WORKFLOW_KEYS.QC_MANAGER, WORKFLOW_KEYS.QC_EXECUTIVE].includes(workflow_permission) ||
  //   (workflow_permission === WORKFLOW_KEYS.PROJECT_MANAGER && workflow_state === "qc_accepted")) ?

  //   (workflow_permission === WORKFLOW_KEYS.QC_EXECUTIVE && workflow_state !== "qc pending") ? false :
  //   (!workflowStateChanged ? (isUpdateActionListAvailable || WORKFLOW_KEYS.PROJECT_MANAGER) : false) :

  //   false;


  const detailsInfo =
    Object.keys(outletDetails.related_workflow_object.other_config).length > 0
      ? JSON.parse(
        outletDetails.related_workflow_object.other_config.data_to_update
      )
      : {};

  const retailerQuestionArray = Object.keys(detailsInfo || {}).map((key, i) => {
    if (
      outletDetails.related_workflow_object.other_config.changed_fields.includes(
        key
      )
    ) {

      return (
        <Fragment>
          <FormRow
            leftSideWidth={"25%"}
            name={capitializeFirstChar(key).split("_").join("")}
            rightElement={
              <Fragment>
                <Container>
                  <DashboardTextBox
                    type="text"
                    boxWidth="240px"
                    boxPadding="10px 16px"
                    value={detailsInfo[key]}
                    placeholder={key}
                    fieldName={key}
                  />



                  {key.toUpperCase().includes("IFSC") ||
                    getIsKeyInString(key, "PAN NUMBER") ||
                    (key.toUpperCase().includes("GST NUMBER") && (
                      <VerificationText isVerified={checkValidation(key)}>
                        {checkValidation(key) ? (
                          <Fragment>
                            <div>
                              <CloseIcon />
                              Not Verified
                            </div>
                            <div>
                              {enableEditAnswers ? (
                                <Button
                                  size="small"
                                  isLoading={loaders[key] || false}
                                  margin="10px 0px 0px 10px"
                                  bgColor={green300}
                                  boxWidth="20px"
                                  boxHeight="30px"
                                  onClick={() =>
                                    verifyDetails(key, detailsInfo[key])
                                  }
                                >
                                  Validate
                                </Button>
                              ) : (
                                ""
                              )}
                            </div>
                          </Fragment>
                        ) : (
                          <span>
                            <VerifiedUserIcon />
                            Verified
                          </span>
                        )}
                      </VerificationText>
                    ))}
                </Container>
              </Fragment>
            }
          />
        </Fragment>
      );
    }
  });
  const saveButton = () => {
    return enableEditAnswers &&
      ((questionRowsWithoutImage?.elements || []).length ||
        (retailerQuestionArray || []).length) !== 0 ? (
      <ButtonWrapper>
        <Button
          size="small"
          margin="12px"
          changeOnlyBgColor
          color={curiousBlue300}
          bgColor={white}
          onClick={() => setOutletGroupDetails(_cloneDeep(outletGroupDetailsCopy))}
        >
          Reset
        </Button>
        <Button
          size="medium"
          type="submit"
          bgColor={green300}
          isLoading={requestInProcess}
          onClick={requestInProcess ? null : () => onSaveAction()}
        // isDisabled={!isAllQuestionAnswered}
        >
          {activeGroupIndex === question_groups.length - 1
            ? "Save"
            : "Save & Next"}
        </Button>
      </ButtonWrapper>
    ) : null
  }

  const handleSliderComplete = (isCompleted) => {
    if(isCompleted){
    console.log('Slider has completed!');
    }
    // Perform actions you want to after slider completes
  };

  
  const travelLog = (outletDetails.related_workflow_object || {}).travel_log
    ? outletDetails.related_workflow_object.travel_log[0]
    : "";
  const showRemarksBelowTexBox = travelLog === "calling_rejected";

  useEffect(() => {
    if (Array.isArray(outletGroupDetails)) {
      outletGroupDetails.forEach((question) => {
        const { related_answers } = question;
        const answerDetails = (related_answers || [])[0];

        if (answerDetails?.answer_type === "gst_number") {
          if (
            answerDetails?.is_gst_trade_name_found === true ||
            answerDetails?.is_gst_legal_name_found === true
          ) {
            setIsGstLegalTradeFound(true);
          }
        }
      });
    }
  }, [outletGroupDetails]);

  const questionRowsWithoutImage =
    Array.isArray(outletGroupDetails) &&
    outletGroupDetails.reduce((questionArray, question, questionIndex) => {
      const {
        name,
        slug,
        reference_images,
        related_answers,
        validations,
        is_mandatory = false,
        type,
      } = question || {};
      const answerDetails = (related_answers || [])[0];
      const details = [];
      const multipleDetails = [];
      const {
        single_input,
        latitude_input,
        longitude_input,
        answer_type,
        previous_answers = {},
        multiple_answers_input=[],
        remarks,
      } = answerDetails || {};
      
      if (type === "yes_or_no" || type === "single_choice"||type === "dropdown") {
        const validationCondition = validations.choices && validations.choices.split(";") || [];
        let validationList =
          validationCondition.length > 0 &&
          validationCondition.map((item, i) => {
            details.push({ label: item, value: item });
          });
      }
      if (type === "multiple_choice") {
         
        const validationCondition = validations.choices && validations.choices.split(";") || [];
        validationCondition.map((item, i) => {
          multipleDetails.push({ label: item, value: item });
        });
      
      }

      if (!["multiple_image", "single_image"].includes(type)) {
        let answerText;
        let answerMultiText=[];
        let answerKey = "";
        if (type.includes("location")) {
          answerText = `${latitude_input},${longitude_input}`;
          answerKey = "latitude_input;longitude_input";
        }
        else if (type === "multiple_choice") {
         
        
          answerMultiText = multiple_answers_input;
          answerKey = "multiple_answers_input";
        }
        else {
          answerText = single_input;
          answerKey = "single_input";
        }
        let additionalFields = [];
        // Extra Fields for PAN
        if (type.includes("pan_number")) {
          const panDetails = [
            {
              label: "Pan Holder Name",
              type: "text",
              answer: answerDetails.other_pan_holder_name,
              field_name: 'other_pan_holder_name'
            }
          ]
          additionalFields = panDetails
        }

        // Extra Fields for GST
        if (type.includes("gst_number")) {

           const gstDetails = [
            {
              label: "Trade Name",
              type: "text",
              answer: answerDetails.other_gst_trade_name,
              field_name: 'other_gst_trade_name'
            },
            {
              label: "Legal Name",
              type: "text",
              answer: answerDetails.other_gst_legal_name,
              field_name: 'other_gst_legal_name'
            },
            {
              label: "GST Status",
              type: "text",
              answer: answerDetails.other_gst_registration_status,
              field_name: 'other_gst_registration_status'
            }
          ]

          additionalFields = gstDetails
        }

        if (name.toUpperCase().includes("UPI")) {
          const upiDetails = [
            {
              label: "UPI Name",
              type: "text",
              answer: answerDetails.other_upi_account_name,
              field_name: "other_upi_account_name"
            }
          ]
          additionalFields = upiDetails
        }
        const ifscDetails = [
          {
            label: "Branch Name",
            type: "text",
            answer: answerDetails.other_branch_name,
            field_name: "other_branch_name"
          },
          {
            label: "Bank Name",
            type: "text",
            answer: answerDetails.other_bank_name,
            field_name: "other_bank_name"
          }
        ]
        if (name.toUpperCase().includes("IFSC")) {

          additionalFields = ifscDetails
        }
        // Extra fields for Account Number
        // if (name.toUpperCase().includes("ACCOUNT HOLDER NAME"))
        if (type == "account_number") {
          const accountDetails = [
            {
              label: "Phone Number",
              type: "text",
              answer: answerDetails.other_phone_number,
              field_name: "other_phone_number",
              disabled: false
            },
            {
              label: "IFSC",
              type: "text",
              answer: answerDetails.other_ifsc_code,
              field_name: "other_ifsc_code",
              disabled: false,
              verification_key: "verified_secondary",
              isIFSC: true
            },
            ...ifscDetails,
            {
              label: "Account Holder Name",
              type: "text",
              answer: answerDetails.other_account_holder_name,
              field_name: "other_account_holder_name",
            }
          ]
          additionalFields = accountDetails
        }

        const additionalElement = ({ label, type, answer, field_name, disabled = true, isIFSC = false, verification_key = "" }) => {
          return (
            <FormRow
              name={label}
              type={type}
              leftSideWidth={"30%"}
              margin="45px 0"
              rightElement={
                <div>
                  <Fragment>
                    <Container>
                      <div>
                        <DashboardTextBox
                          boxWidth={
                            questionGroupImages.length ? "240px" : "340px"
                          }
                          type={type}
                          disabled={disabled}
                          value={answer}
                          boxPadding="10px 16px"
                          background={black100}
                          placeholder={label}
                          fieldName={field_name}
                          onChange={
                            (e) => {  
                              setSubIfscVerification(false);
                              answerDetails[verification_key] = false
                              onChange({ e, questionIndex, type });
                            }
                          }
                        />
                        { 
                          ((label === "Trade Name" || label === "Legal Name") && isGstLegalTradeFound) &&(
                            <div className="error-icon-container" style={{ marginLeft: '105%', transform: 'translateY(-100%)' }}>
                            <Error style={{ color: 'red', fontSize: '200%' }} onClick={openModal} />
                          </div>
                          )
                        }
                        {isModalOpen && (
                          <Modal
                            withHeader
                            top="25%"
                            modalWidth="35%"
                            noContainerShadow={true}
                            modalHeight="40%"
                            backDropOpacity={0.3}
                            closeIconColor={black300}
                            closeAction={onCancel}
                          >
                            <div>
                              <Error style={{ color: 'red', fontSize: '500%', marginTop: '5%', marginLeft: '47%'}}/>
                              <h4 style={{fontSize: '135%', marginTop: '10%', marginLeft: '25%'}}>Trade name or Legal name are same!</h4>
                              <Button margin={'5% 43.5%'} onClick={onCancel} >
                                OK
                              </Button>
                            </div>
                          </Modal>
                        )}
                      </div>
                      {isIFSC && (
                        <VerificationText isVerified={!answerDetails[verification_key] || subIfscVerification}>
                          {!answerDetails[verification_key] || subIfscVerification ? (
                            <div>
                              <span>
                                <CloseIcon />
                                Not Verified
                              </span>
                              {enableEditAnswers ? (
                                <Button
                                  size="small"
                                  isLoading={loaders["IFSC-sub"] || false}
                                  margin={
                                    questionGroupImages.length
                                      ? "5px 0px 0px 50px"
                                      : "5px 0px 0px 140px"
                                  }
                                  bgColor={green300}
                                  boxWidth="20px"
                                  boxHeight="30px"
                                  onClick={() => {
                                    verifyDetails("IFSC-sub", answerDetails.other_ifsc_code, questionIndex, type)
                                  }

                                  }
                                >
                                  Validate
                                </Button>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <span>
                              <VerifiedUserIcon />
                              Verified
                            </span>
                          )}
                        </VerificationText>
                      )}
                    </Container>
                  </Fragment>
                </div>
              }
            />
          )
        }

        const element = (
          <FormRow
            name={name}
            asterisk={is_mandatory}
            leftSideWidth={"30%"}
            margin="25px 0"
            rightElement={
              <div>
                {enableEditAnswers &&
                  (type === "yes_or_no" || type === "single_choice"|| type === "dropdown") ? (
                  <SelectFilter
                    props={{
                      width: questionGroupImages.length ? "240px" : "340px",
                      border: `1px solid ${black200}`,
                      placeholderColor: black700,
                      options: details,
                      fieldName: answerKey,
                      value: details.filter(
                        (condition) => condition.value === answerText
                      ),
                      onChange: (e) => {

                        onChange({ e, questionIndex, type });
                      },
                    }}
                    customStyles={{
                      borderRadius: "2px",
                      background: black200,
                    }}
                    noOptionMessage="No data available"
                  />
                ) : 
                  (type === "multiple_choice") ?
                  (
                    <SelectFilter
                      customStyles={{
                        borderRadius: "2px",
                        background: white,
                      }}
                      props={{
                        placeholder: "Select " + name,
                        border: `1px solid ${black200}`,
                        placeholderColor: black700,
                        isDisabled:!enableEditAnswers,
                        // value: multipleDetails.filter(
                        //   (inputValues[option] || []).includes(
                        //       project.value
                        //     )
                        // ),
                        value: multipleDetails.filter(({ value }) =>
                          multiple_answers_input.map(input => input.toLowerCase()).includes(value.toLowerCase())
                        ),
                        onChange: (e) => {
                          onChange({ e, questionIndex, type });
                        },
                       
                        options: multipleDetails,
                        fieldName: answerKey,
                        width: questionGroupImages.length ? "240px" : "340px",
                        border: `1px solid ${black200}`,

                        // value: dropdownOptions.filter((project) =>
                        // (inputValues[option] || []).includes(
                        //   project.value
                        // )),
                        isMulti:
                          true
                      }}
                      
                    // {...selectFieldProps}
                    noOptionMessage="No data available"
                    />
                  
                  )
                  :
                  (
                    <Fragment>
                      <Container>
                        <div>
                          <DashboardTextBox
                            boxWidth={
                              questionGroupImages.length ? "240px" : "340px"
                            }
                            boxPadding="10px 16px"
                            type={answer_type === "number" ? "number" : "text"}
                            disabled={!enableEditAnswers}
                            value={answerText && type === 'phone_number' ? (answerText || "").includes("+91") ? answerText : `+91 ${answerText}` : name.toUpperCase().includes("GST NUMBER") || name.toUpperCase().includes("PAN") ? (answerText || "") && answerText.toUpperCase() : answerText || ""}
                            // value={single_input || ""}
                            background={black100}
                            placeholder="text"
                            fieldName={answerKey}
                            onChange={(e) => {
                              if (name.toUpperCase().includes("IFSC")) {
                                setIfscVerificationStatus("not_verified_yet");
                                answerDetails.verified = false
                                setIfscCode(e.target.value);
                              } else if (name.toUpperCase().includes("PAN")) {
                                setPanVerificationStatus("not_verified_yet");
                                answerDetails.verified = false

                                setPan(e.target.value);
                              } else if (name.toUpperCase().includes("GST NUMBER")) {
                                setGstVerificationStatus("not_verified_yet")
                                answerDetails.verified = false
                                setGst(e.target.value);
                              }
                              else if (name.toUpperCase().includes("BANK ACCOUNT")) {
                                answerDetails.verified = false
                              }
                              else if (name.toUpperCase().includes("ACCOUNT NUMBER")) {
                                setAccountVerificationStatus("not_verified_yet")
                                answerDetails.verified = false
                              } else if (name.toUpperCase().includes("UPI")) {
                                answerDetails.verified = false
                              }
                              onChange({ e, questionIndex, type });
                            }}
                            warnMessage={
                              showRemarksBelowTexBox
                                ? {
                                  text: remarks,
                                  align: "right",
                                  onlyTextError: true,
                                }
                                : {}
                            }
                          />
                          {name.toUpperCase().includes("IFSC") ||
                            type.includes("pan_number") ||
                            type.includes("gst_number") ||
                            type.includes("account_number") ||
                            name.toUpperCase().includes("UPI") ? (
                            <VerificationText isVerified={!answerDetails.verified}>
                              {!answerDetails.verified ? (
                                <div>
                                  <span>
                                    <CloseIcon />
                                    Not Verified
                                  </span>
                                  {enableEditAnswers ? (
                                    <Button
                                      size="small"
                                      isLoading={loaders[name] || false}
                                      margin={
                                        questionGroupImages.length
                                          ? "5px 0px 0px 50px"
                                          : "5px 0px 0px 140px"
                                      }
                                      bgColor={green300}
                                      boxWidth="20px"
                                      boxHeight="30px"
                                      onClick={() =>
                                        verifyDetails(name, answerText, questionIndex, type)
                                      }
                                    >
                                      Validate
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <span>
                                  <VerifiedUserIcon />
                                  Verified
                                </span>
                              )}
                            </VerificationText>
                          ) : (
                            ""
                          )}
                        </div>
                      </Container>
                    </Fragment>
                  )}
                {Object.keys(previous_answers).map((key) => (
                  <>
                    <TextWrapper data-tip data-for={`phoneNumber${key}`}>
                      {getDayOrNumberSuffix(key)} Prev: &nbsp;&nbsp;
                      {previous_answers[key].single_input}
                    </TextWrapper>
                    <ReactTooltip id={`phoneNumber${key}`} place={"bottom"} type={"dark"}>
                      <p>{previous_answers[key].sub_project_name}</p>
                    </ReactTooltip>
                  </>

                ))}
              </div>
            }
          />
        );
        questionArray.elements.push(element);
        additionalFields.map(field => {
          questionArray.elements.push(additionalElement(field))
        })

      } else {
        {
          Object.keys(previous_answers).map((key) => {
            // Init array if not already exists
            questionArray.prevWorkflowID[key] = previous_answers[key].workflow_object

            if (!(questionArray.prevAnsImages[key])) {
              questionArray.prevAnsImages[key] = []
            }

            if (type === 'single_image') {
              questionArray.prevAnsImages[key].push(previous_answers[key].single_image_input.image)
            } else {
              questionArray.prevAnsImages[key] = [...questionArray.prevAnsImages[key], ...((previous_answers[key].multiple_images_input || []).map(a => a.image))]
            }
          })
        }
      }

      return questionArray;
    }, { elements: [], prevAnsImages: {}, prevWorkflowID: {} });

    



    return (
      <Fragment>
        <Title>{sub_project.name}</Title>
        {question_groups || [].length ? (
          <VerticalNav>
            <Slider {...settings} ref={sliderRef}>
              {question_groups.map((group, index) => (
                <VerticalNavItem
                  className={
                    index === activeQuestionGroupIndex?.position_index || 0
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    setActiveGroupIndex(index);
                    setActiveQuestionGroupIndex({
                      group_index: group?.id,
                      position_index: index,
                    });
                  }}
                >
                  <span>{group.name}</span>
                  {/* {enableEditAnswers &&
                  ((saveClickedData || {}).hasOwnProperty(index) &&
                    (group.name === "Collected Bills" && isSliderComplete)) ? (
                    <i />
                  ) : null} */}
                  {enableEditAnswers && (
            (group.name === "Collected Bills"
              ? isSliderComplete // For "Collected Bills", check isSliderComplete
              : (saveClickedData || {}).hasOwnProperty(index) // For others, use the original condition
            ) ? <i /> : null
          )}
                </VerticalNavItem>
              ))}
            </Slider>
          </VerticalNav>
        ) : null}
        {isLoading ? (
          <Loader />
        ) : (
          <ContentWrapper>
            {outletDetails.question_groups[activeGroupIndex]?.name ===
            "Collected Bills" ? (
              <div>
                <CollectedBills
                  outletDetails={outletDetails}
                  history={history}
                  enableEditAnswers={enableEditAnswers}
                  setIsSliderComplete={setIsSliderComplete}
                />
              </div>
            ) : Array.isArray(outletDetails.question_groups) &&
              outletDetails.question_groups[activeGroupIndex]?.type &&
              isSkuGroup &&
              outletGroupDetails.length > 0 ? (
              <SkuTableOutlet
                sku_products={
                  outletDetails.question_groups[activeGroupIndex]
                    .related_sku_products
                }
                outlet_group_details={outletGroupDetails}
                on_save={onSaveAction}
                saveButton={saveButton}
                disabled={!enableEditAnswers}
                requestInProcess={requestInProcess}
              />
            ) : (
              <>
                <QuestionContainer>
                  {!isLoading && questionRowsWithoutImage?.elements}
                  <p>
                    {((questionRowsWithoutImage?.elements || []).length ||
                      (retailerQuestionArray || []).length) === 0
                      ? "No Data available"
                      : ""}
                  </p>
                  {enableEditAnswers &&
                  ((questionRowsWithoutImage?.elements || []).length ||
                    (retailerQuestionArray || []).length) !== 0 ? (
                    <ButtonWrapper>
                      <Button
                        size="small"
                        margin="12px"
                        changeOnlyBgColor
                        color={curiousBlue300}
                        bgColor={white}
                        onClick={() =>
                          setOutletGroupDetails(
                            _cloneDeep(outletGroupDetailsCopy)
                          )
                        }
                      >
                        Reset
                      </Button>
                      <Button
                        size="medium"
                        type="submit"
                        bgColor={green300}
                        isLoading={requestInProcess}
                        onClick={requestInProcess ? null : () => onSaveAction()}
                      >
                        {activeGroupIndex === question_groups.length - 1
                          ? "Save"
                          : "Save & Next"}
                      </Button>
                    </ButtonWrapper>
                  ) : null}
                </QuestionContainer>
              </>
            )}
            {outletDetails.question_groups[activeGroupIndex]?.name !==
              "Collected Bills" && (
              <div>
                <ImageZoom
                  showPrevSurveyImageTiles={true}
                  prevAnsImages={questionRowsWithoutImage.prevAnsImages}
                  showCurrentSurveyImageTiles={
                    !isSkuGroup && questionGroupImages.length
                  }
                  currentSurveyImages={isSkuGroup ? [] : questionGroupImages}
                  defaultActiveImage={questionGroupImages[0]}
                  totalImageCount={outletDetails.total_images_linked_to_answers}
                  totalImages={totalImages}
                  imageSectionwidth={
                    questionGroupImages.length && !isSkuGroup ? "" : "200px"
                  }
                  isSkuGroup={isSkuGroup}
                  newTab={true}
                  outlet_slug={outletDetails.slug}
                  outlet_id={outletDetails.id}
                  workflowId={workflowId}
                  workflowSlug={getCurrentSlug(activeGroupIndex)}
                  prevWorkflowId={outletDetails?.previous_outlet?.outlet_id}
                />
              </div>
            )}
          </ContentWrapper>
        )}
      </Fragment>
    );
};
const mapDispatchToProps = (dispatch) => {
  return {
    triggerNotification: bindActionCreators(triggerNotification, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(OutletSurvey);

