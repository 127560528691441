import React from 'react';
import styled, { css }from 'styled-components';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { black400, cinnabar300 } from '../../../theme/colors';
import { baseCaption } from '../../../theme/typography';
import themeSizes from '../../../theme/themeSizes';

const SelectWrapper = styled.div`
    display: ${props => props.display || 'flex'};
    margin-right: ${props => props.marginRight || '0px'};
    margin-bottom: ${props=>props.marginBottom || '0px' }  ;
    margin-left: ${props => props.marginLeft || '0px'};
    flex-direction:column;
    ${props => props.isDisable && css`
       cursor:default
    `}
`;
const SelectStyle = styled(Select)`
    width:${props => props.width || '540px'};
    margin-bottom: ${props=>props.marginBottom || '0px' }  ; 
    margin-left: ${props => props.marginLeft || '0px'};
    text-transform: ${props => props.capitalize || ""};
    :focus{
        outline:none;
    }
    &>div:first-child, &>span+div{
        min-height:${props => props.height || '42px'};
        border: ${props => props.noBorder ? 'none' : props.hasError ? `1px solid ${cinnabar300}` : props.border || `1px solid hsl(0,0%,80%)`};
        div {
            span{
                display:none;
            }
        }
    }
    &>div:last-child{
        span{
            display:none;
        }
    };
    @media ${themeSizes.tablet} {
        width:100% !important;
      }
`;
const CreatableStyle = styled(Creatable)`
    width:${props => props.width || '540px'};
    :focus{
        outline:none;
    }
    &>div:first-child, &>span+div{
        min-height:${props => props.height || '42px'};
        border: ${props => props.noBorder ? 'none' : props.hasError ? `1px solid ${cinnabar300}` : props.border || `1px solid hsl(0,0%,80%)`};
        div {
            span{
                display:none;
            }
        }
    }
    &>div:last-child{
        span{
            display:none;
        }
    };
    @media ${themeSizes.tablet} {
        width:100% !important;
      }
`;
const ErrorMessageStyle = styled.p`
    ${baseCaption}
    color: ${cinnabar300};
    margin:5px 0 0;
    text-align: ${props => props.textAlignMent || 'right'};
`;

export const SelectFilter = ({
     props={},
     subText,
     warnMessage,
     rootProps,
     height,
     capitalizeFirstChar="capitalize",
     borderRadius=0,
     customStyles,
     containerStyles,
     dropdownColor,
     noOptionMessage,
     menuStyles,
     isCreatable = false,
     styleProps={},

}) => {
    const SelectComponent = isCreatable ? CreatableStyle : SelectStyle;
    return (
            <SelectWrapper {...rootProps} isDisable = {props.isDisabled}>
                <SelectComponent
                    {...props}
                    styles = {{
                        control:base =>({
                            ...base,
                            ...customStyles
                        }),
                        container:base =>({...base,...containerStyles}),//container styles - width,margin
                        dropdownIndicator: base => ({
                            ...base,
                            color: dropdownColor || "#000000" // Custom colour
                          }),
                        menu: (provided, state) => ({
                        ...provided,
                        'z-index':10,
                        ...menuStyles
                        }),
                        ...styleProps
                    }}
                    noOptionsMessage={() => noOptionMessage || 'No Data'}
                    height = {height}
                    capitalize = {capitalizeFirstChar}
                    hasError={(warnMessage || {}).text}
                    theme={theme => ({
                        ...theme,
                        borderRadius:borderRadius,
                        colors: {
                            ...theme.colors,
                            neutral50: props.placeholderColor || black400  // Placeholder color
                        },
                    })}
                 
            />
            {subText}
            {(warnMessage || {}).text &&
                <ErrorMessageStyle textAlignMent={warnMessage.align} >
                    {warnMessage.text}
                </ErrorMessageStyle>
            }
        </SelectWrapper>
    )
}
